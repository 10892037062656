import EditAssignmentsWithFieldTypes from 'components/Popups/EditAssignmentsWithFieldTypes'
import DialogHeader from 'components/Popups/EditAssignmentsWithFieldTypes/DialogHeader'
import { PageContextProvider } from 'pageContext'
import React, { useState } from 'react'

export const AssignRightsToManagementRole = ({
    targetAssigneeId,
    resourceId,
    dynamictext,
    resourceSystemModuleID,
    showAssignmentModal,
    setAssignmentModalValue,
}) => {
    const [assignee, setAssignee] = useState(null)

    return (
        <>
            <PageContextProvider key={'AssignRightsToManagementRolesPopup'}>
                <DialogHeader
                    headerText={{
                        dynamicText: dynamictext,
                        text: 'AssignRightsToManagementRole',
                    }}
                    setShowAssignmentModal={(val) => {
                        if (val === false) {
                            setAssignmentModalValue('')
                            setAssignee(null)
                        }
                    }}
                    showAssignmentModal={showAssignmentModal}
                    assigneePerson={assignee}
                    setAssigneePerson={setAssignee}
                    personSelectorLabelKey={
                        'SelectManagementRoleToAssignRights'
                    }
                    personSelectorUrl={`api/ManagementRoles/all`}
                    personSelectorQueryParams={{
                        resourceId: resourceId,
                        showLocalRights: true,
                    }}
                    personSelectorPlaceholderKey={'SelectManagementRole'}
                    showPersonSelector={true}
                >
                    {assignee && (
                        <EditAssignmentsWithFieldTypes
                            resourceType={'Applications'}
                            assignedUrl={`api/LocalRights/appRoles/membersByApplicationID`}
                            unAssignedUrl={`api/LocalRights/GetEligibleAppRightsByApplicationByAssignee`}
                            targetAssigneeId={targetAssigneeId}
                            searchPlaceHolder="SearchRights"
                            assignedQueryParams={{
                                resourceSystemModuleID: resourceSystemModuleID,
                                assigneeId: assignee?.id,
                            }}
                            unAssignedQueryParams={{
                                applicationId: resourceId,
                                eligibilityAssigneeId: assignee?.id,
                                isAssigned: false,
                            }}
                            showAccessRequestPolicy={true}
                            fetchRequestPolicyItemWise={true}
                            showStartDate={false}
                            fieldTypeProps={{
                                assignee: assignee,
                                isRole: false,
                                keyForRoleRightId: 'localRightId',
                            }}
                            requireRemappingInAssignedList={true}
                            newMappingForAssignedList={{
                                friendlyName: 'appRightFriendlyName',
                            }}
                            showAssignmentModal={showAssignmentModal}
                            handleClose={() => {
                                setAssignmentModalValue('')
                                setAssignee(null)
                            }}
                        />
                    )}
                </DialogHeader>
            </PageContextProvider>
        </>
    )
}
