import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, styled } from '@mui/material'
import { useGroupMembers, useDeleteGroupMember, useGroup } from 'hooks'
import { Avatar, ToggleSwitch, WarningModal } from 'packages/eid-ui'
import { RESOURCE_TYPE_NAMES, getFullImageUrl, imagesDisabled } from 'utils'
import DateTimeGenericComponent from '../../../../components/DynamicComponents/DateTimeGenericComponent'
import { PageContextProvider, usePageContext } from 'pageContext'
import useSubcomponents from 'useSubcomponents'
import { Button, Filters } from 'components'
import { Icon } from 'packages/eid-icons'
import BooleanIcon from 'components/DynamicComponents/BooleanIcon'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import AddNewAssignment from 'components/Popups/AddNewAssignment/AddNewAssignment'
const canChangeBetweenDirectAndResultantMembersControlName =
    'ResourceAdmin-GroupDetails-AccountsAsMembers-ChangeMembersType-Control'
const canRemoveMultipleMembersControlName =
    'ResourceAdmin-GroupDetails-AccountsAsMembers-RemoveMultipleMembers-Control'
const canRemoveSingleMemberControlName =
    'ResourceAdmin-GroupDetails-AccountsAsMembers-RemoveMultipleMembers-Control'

export const SwitchContainer = styled(({ value, ...rest }) => (
    <Box {...rest} />
))(({ value }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    marginRight: '10px',
    '& >p': {
        fontSize: '14px',
    },
    '& .Label': {
        color: value ? '#8b909a' : '#000000',
    },
    '& .SubLabel': {
        color: value ? '#000000' : '#8b909a',
    },
    '& .MuiFormControlLabel-root': {
        marginLeft: '5px',
        marginRight: '5px',
    },
}))
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const DeleteButton = styled(Box)({
    paddingRight: '10px',
    '& button': {
        padding: '8px 12px',
        borderRadius: ' 4px',
        border: 'solid 1px #ff4d5f',
        backgroundColor: '#fff',
    },
})
export const Members = ({ groupId }) => {
    const { t } = useTranslation()
    const [selected, setSelected] = useState([])
    const [showWarningModal, setShowWarningModal] = useState(false)
    const { data: group } = useGroup(groupId)
    const { getAccessBasedAttributes } = useSubcomponents()
    const [isResultant, setIsResultant] = useState(false)
    const { hasAccessToControl } = useSubcomponents()
    const [deleteMember, { isLoading: isDeletingMember, isSuccess }] =
        useDeleteGroupMember(groupId)
    const [, dispatch] = usePageContext()
    useEffect(() => {
        if (isSuccess) {
            setSelected([])
        }
    }, [isSuccess, isDeletingMember])

    const handleDelete = () => {
        deleteMember(selected).then(() => {
            setShowWarningModal(false)
        })
    }

    const {
        list,
        loading: isLoading,
        pagination,
    } = useGroupMembers(groupId, isResultant)

    const resultantAttributes = [
        {
            label: 'FriendlyName',
            name: 'friendlyName',
            sortable: true,
            style: {
                fontWeight: 'bold',
            },
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-Groups-Details-ResultantMembers-List-FriendlyName',
            // },
        },
        {
            label: 'Enabled',
            name: 'disabled',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-ResultantMembers-List-Enabled',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <BooleanIcon
                        data={{ item }}
                        attribute={{ name: 'disabled', reverse: true }}
                    />
                ),
            },
        },
        {
            label: 'Type',
            name: 'userType',
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-ResultantMembers-List-Type',
            },
        },
        {
            name: 'lastLogonTime',
            label: 'LastLogin',
            sortable: true,
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-ResultantMembers-List-LastLogin',
            },
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <DateTimeGenericComponent
                        data={{ item }}
                        attribute={{ name: 'lastLogonTime' }}
                    />
                ),
            },
        },
        {
            name: 'logonName',
            label: 'LogonName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-ResultantMembers-List-LogonName',
            },
        },
        {
            name: 'domainOrDirectory',
            label: 'DomainOrDirectory',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-ResultantMembers-List-DomainOrDirectory',
            },
        },
        {
            name: 'distinguishedName',
            label: 'DistinguishedName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-ResultantMembers-List-DistinguishedName',
            },
        },
    ]
    const directAttributes = [
        {
            label: '',
            name: 'image',
            image: true,
            hideValue: true,
            showInCard: false,
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <Avatar
                        size="medium"
                        src={getFullImageUrl(item.imageThumbUrl)}
                        style={{
                            boxShadow: 'rgb(110 110 111) 0px 0px 8px 0px',
                        }}
                    />
                ),
            },
            requireAccess: {
                control: 'ResourceAdmin-Groups-Details-Members-List-Avatar',
            },
        },
        {
            hideValue: true,
            showInCard: false,
            showInTable: false,
            image: true,
            component: {
                componentName: 'ResourceImage',
                name: 'imageThumbUrl',
                defaultUrl: '/webcdn/Images/AppLogos/Genric-1.png',
            },
        },
        {
            label: 'AccountUserName',
            name: 'accountPreviewName',
            sortable: true,
            style: {
                fontWeight: 'bold',
            },
            isHeader: true,
            showInCard: false,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-Groups-Details-Members-List-AccountUserName',
            // },
        },
        {
            label: 'RbacAssigned',
            name: 'rbacAssigned',
            sortable: true,
            hideValue: true,

            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-Members-List-RbacAssigned',
            },
            component: {
                componentName: 'renderer',
                render: (item) =>
                    item.rbacAssigned === null
                        ? '-'
                        : item.rbacAssigned
                        ? t('Yes')
                        : t('No'),
            },
        },
        {
            name: 'accountStoreName',
            label: 'AccountDomain',
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-Members-List-AccountDomain',
            },
        },
        {
            name: 'accountFriendlyName',
            label: 'AccountDisplayName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-Members-List-AccountDisplayName',
            },
        },
        {
            name: 'createdFromAccountStore',
            label: 'AddedInAccountStore',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-Members-List-AddedInAccountStore',
            },
            hideValue: true,

            component: {
                componentName: 'renderer',
                render: (item) =>
                    item.createdFromAccountStore === null
                        ? '-'
                        : item.createdFromAccountStore
                        ? t('Yes')
                        : t('No'),
            },
        },
        {
            name: 'lastAttestationDateTimeUtc',
            label: 'LastCertified',
            sortable: true,
            hideValue: true,

            requireAccess: {
                control:
                    'ResourceAdmin-Groups-Details-Members-List-LastCertified',
            },
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <DateTimeGenericComponent
                        data={item}
                        attribute={{ name: 'lastAttestationDateTimeUtc' }}
                    />
                ),
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        hasAccessToControl(
                            canRemoveSingleMemberControlName,
                        ) && (
                            <DeleteButton
                                data-protectedsubcomponent={
                                    canRemoveSingleMemberControlName
                                }
                            >
                                <Button.Delete
                                    showEndIcon={false}
                                    onClick={() => {
                                        setSelected([data.accountId])
                                        setShowWarningModal(true)
                                    }}
                                />
                            </DeleteButton>
                        )
                    )
                },
            },
        },
    ]
    const selectAllAction = hasAccessToControl(
        canRemoveMultipleMembersControlName,
    )
        ? {
              render: () => {
                  return (
                      <Box>
                          <BulkRemoveButton
                              selectedCount={selected.length}
                              label={t('RemoveSelectedMembers', {
                                  count: selected.length,
                              })}
                              onClick={handleBulkAction}
                              dataProtectedsubcomponent={
                                  canRemoveMultipleMembersControlName
                              }
                          />
                      </Box>
                  )
              },
          }
        : null

    const handleBulkAction = () => {
        setShowWarningModal(true)
    }
    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x === item.accountId)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x !== item.accountId
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item.accountId])
        }
    }
    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            const filteredList = list.map((item) => item.accountId)
            setSelected(filteredList)
        }
    }
    const filteredAttributes = getAccessBasedAttributes(
        isResultant ? resultantAttributes : directAttributes,
    ).filter((x) => !x.image || !imagesDisabled)

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                paddingBottom="16px"
            >
                <PageContextProvider key="AddNewMembersGroups">
                    <AddNewAssignment
                        resourceType={RESOURCE_TYPE_NAMES.GROUPS}
                        labelKey={'AddNewAccount'}
                        searchUrl={`api/applicationRoles/assignableMembers/${groupId}`}
                        showPermissionLevel={false}
                        identifier={'accountId'}
                        targetAssigneeId={groupId}
                        targetAssigneeResource={group}
                        secondaryAttributes={[
                            'accountStoreFriendlyName',
                            'logonName',
                        ]}
                        startIcon={
                            <Icon
                                name={'Plus'}
                                fill="white"
                                style={{
                                    width: '1.6rem',
                                    height: '1.6rem',
                                    marginRight: '0.5rem',
                                }}
                            />
                        }
                    />
                </PageContextProvider>
                <Box
                    style={{
                        margin: '0px 0px 0px auto',
                        display: 'flex',
                        gap: 20,
                    }}
                >
                    {canChangeBetweenDirectAndResultantMembersControlName && (
                        <Box
                            data-protectedsubcomponent={
                                canChangeBetweenDirectAndResultantMembersControlName
                            }
                        >
                            <SwitchContainer value={isResultant}>
                                <Typography className="Label">
                                    {t('Direct')}
                                </Typography>
                                <ToggleSwitch
                                    value={isResultant}
                                    onChange={() => {
                                        setIsResultant((p) => !p)
                                        dispatch({
                                            type: 'SET_MULTIPLE_PROPS',
                                            payload: {
                                                searchTerm: '',
                                                page: 1,
                                            },
                                        })
                                    }}
                                />
                                {t('Resultant')}

                                <Typography className="SubLabel"></Typography>
                            </SwitchContainer>
                        </Box>
                    )}
                    <Box
                        width="240px"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <SearchMenuBarFilter>
                            <Filters.TextSearch outlined />
                        </SearchMenuBarFilter>
                    </Box>
                </Box>
            </Box>

            <EidTableGrid
                selectAllAction={!isResultant && selectAllAction}
                rowSelection={!isResultant}
                handleSelectAllClick={handleSelectAllClick}
                selected={selected}
                handleClick={handleRowSelection}
                noDataMessage={t('NoDataMessage')}
                pagination={pagination}
                isLoading={isLoading}
                attributes={filteredAttributes}
                list={list}
                selectionIdentifier={'accountId'}
            />
            {selected.length > 0 && (
                <WarningModal
                    title={t('DeleteMembers')}
                    description={t('MembersDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={showWarningModal}
                    cancel={() => setSelected([])}
                    proceeding={isDeletingMember}
                    proceed={handleDelete}
                />
            )}
        </>
    )
}
