import { Box, Typography, styled, Dialog, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Button, ItemDetails } from 'components'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import config from 'config'
import { Avatar, WithCloseButton } from 'packages/eid-ui'
import AdvancePersonFinder from 'components/AdvancePersonFinder'

export const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        right: '0rem',
        cursor: 'pointer',
        marginTop: '-1.2rem',
    },
})
const PersonValueIndicator = styled(WithCloseButton)({
    borderRadius: '0.5rem',
    backgroundColor: '#535b62',
    padding: '1rem 2.2rem 1rem 1.6rem',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
})
const AssignmentsModal = styled(Box)({
    maxWidth: '44rem',
    width: '100%',
    background: '#fff !important',
    border: 'solid 0.1rem #ebebed',
    '& h2': {
        fontSize: '2.4rem',
        color: '#282828',
    },
})

export const CustomDialog = styled(Dialog)({
    zIndex: 1200,
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '83rem',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '83rem',
            width: '100%',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

export const Divider = styled(Box)({
    background: '#d8d8d8 !important',
    height: '0.1rem',
})

const useStyles = makeStyles((theme) => ({
    headerBox: {
        padding: '3.2rem 2rem 3.2rem 4rem',
        backgroundColor: '#ffffff !important',
        maxHeight: '8.4rem',
    },
    tabsBox: {
        height: '6.4rem',
    },
    itemTabMenuBar: {
        padding: '0 1.7rem',
    },
    bodyBox: {
        padding: '2.4rem 3.2rem 3.2rem 3.2rem',
    },
}))

const DialogHeader = ({
    labelKey,
    startIcon,
    headerText,
    handleClose = null,
    showAssignmentModal = false,
    setShowAssignmentModal,
    children,
    allTabs,
    activeTab,
    handleTabChange,
    showPersonSelector = false,
    personSelectorUrl = '',
    personSelectorLabelKey = '',
    personSelectorQueryParams = {},
    assigneePerson = null,
    setAssigneePerson,
    personSelectorplaceholderKey = '',
    showAdvanceSearch = false,
}) => {
    const { t } = useTranslation()
    const classes = useStyles({})
    const theme = useTheme()

    const handleModalClose = () => {
        setShowAssignmentModal(false)
        if (handleClose) {
            handleClose()
        }
    }

    return (
        <>
            {(labelKey || startIcon) && (
                <Button
                    onClick={() => setShowAssignmentModal(true)}
                    style={{
                        borderRadius: '0.4rem',
                        backgroundColor: theme?.palette?.primary?.main,
                        color: '#ffffff',
                        fontSize: '1.4rem',
                        height: '100%',
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.main,
                        },
                    }}
                >
                    <Box style={{ display: 'flex' }}>
                        {startIcon}
                        {t(labelKey)}
                    </Box>
                </Button>
            )}
            <CustomDialog
                open={showAssignmentModal}
                onClose={() => handleModalClose()}
                title={t('')}
            >
                <AssignmentsModal>
                    <Box className={classes.headerBox}>
                        <CloseIconeBox onClick={() => handleModalClose()}>
                            <Icon
                                name="Close"
                                width="1.2rem"
                                height="1.3rem"
                                color="#959598"
                            />
                        </CloseIconeBox>

                        <Typography variant="h6">
                            {headerText ? (
                                <span>
                                    <strong>{headerText.dynamicText}</strong>
                                    {` - ${t(headerText.text)}`}
                                </span>
                            ) : (
                                <strong>{t(labelKey)}</strong>
                            )}
                        </Typography>
                    </Box>

                    {allTabs && allTabs.length > 0 && (
                        <Box className={classes.tabsBox}>
                            <Divider />
                            <ItemDetails.Tabs
                                variant="standard"
                                value={activeTab}
                                onChange={(_, value) => handleTabChange(value)}
                                className={classes.itemTabMenuBar}
                            >
                                {allTabs.map((tab, index) => (
                                    <ItemDetails.Tabs.Tab
                                        key={`rights-tab-${index}`}
                                        value={tab.value}
                                        label={t(tab.labelKey)}
                                    />
                                ))}
                            </ItemDetails.Tabs>

                            <Divider />
                        </Box>
                    )}

                    <Box className={classes.bodyBox}>
                        {showPersonSelector && (
                            <Box style={{ width: '31.6rem' }}>
                                {assigneePerson ? (
                                    <Box style={{ width: '26rem' }}>
                                        <Typography
                                            sx={{
                                                fontSize: '1.2rem',
                                                color: '#b4b4b4',
                                                textTransform: 'uppercase',
                                                mb: '0.8rem',
                                            }}
                                        >
                                            {t(personSelectorLabelKey)}
                                        </Typography>
                                        <PersonValueIndicator
                                            onClose={() => {
                                                setAssigneePerson(null)
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: '2.1rem',
                                                    backgroundColor: '#535b62',
                                                    width: 'auto',
                                                }}
                                            >
                                                <Avatar
                                                    src={`${config.BASE_EID_URL}${assigneePerson.imageThumbUrl}`}
                                                />
                                            </Box>
                                            <Typography
                                                style={{
                                                    wordBreak: 'break-all',
                                                }}
                                                variant="body1"
                                            >
                                                {assigneePerson.friendlyName}
                                            </Typography>
                                        </PersonValueIndicator>
                                    </Box>
                                ) : (
                                    <AdvancePersonFinder
                                        label={t(personSelectorLabelKey)}
                                        value={assigneePerson}
                                        placeholder={t(
                                            personSelectorplaceholderKey,
                                        )}
                                        advanceSearch={showAdvanceSearch}
                                        url={personSelectorUrl}
                                        queryParams={{
                                            ...personSelectorQueryParams,
                                        }}
                                        onChange={(_, val) => {
                                            setAssigneePerson(val)
                                        }}
                                    />
                                )}
                            </Box>
                        )}
                        {showPersonSelector && (
                            <Divider sx={{ margin: '1.6rem 0' }} />
                        )}
                        {children}
                    </Box>
                </AssignmentsModal>
            </CustomDialog>
        </>
    )
}

export default DialogHeader
