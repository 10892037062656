import { Fragment, useState } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Paper } from '@mui/material'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { Owners } from '../Owners'
import {
    useApplication,
    useAccessRequestPolicy,
    useDirectAssignedLocations,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import { AzureApplicationOwners } from '../AzureApplicationOwners'
import { AzureApplicationDeputies } from '../AzureApplicationDeputies'
import useSubcomponents from 'useSubcomponents'
import { MOBILE_SCREEN_WIDTH } from 'packages/core/utils/constants'
import { ResponsibleParty } from '../ResponsibleParties'
import {
    isApplicationAzureApp,
    isApplicationPbacApp,
    convertListToFriendlyNameCsv,
    isApplicationAzureAppWithPbac,
} from 'utils'
import ItemDetails from 'components/ItemDetails'
import { useIsSmallScreenFalse } from 'packages/core'
import Eligibility from './Eligibility'
import { PageContextProvider } from 'pageContext'
import EligibilityPeople from './EligibilityPeople'
const smallScreenWidth = 960

const canSeeOwnersControlName =
    'ResourceAdmin-ApplicationDetails-Overview-Owners-Control'
const canSeeDeputiesControlName =
    'ResourceAdmin-ApplicationDetails-Overview-Deputies-Control'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                display: 'none',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: theme?.palette?.primary?.main,
            textTransform: 'none',
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
        paperContainer: {
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                overflow: 'hidden',
            },
        },
    }),
)

const allSections = [
    {
        name: 'applicationDetails',
        requireAccess: 'ResourceAdmin-ApplicationDetails-GeneralDetails-Column',
    },
    {
        name: 'azureApplicationDetails',
        requireAccess:
            'ResourceAdmin-AzureApplicationDetails-GeneralDetails-Column',
    },
]

const subTabs = [
    {
        value: 'eligibility',
        labelKey: 'Eligibility',
        display: true,
        requireAccess:
            'ResourceAdmin-Applications-Details-Overview-Eligibility',
    },
    {
        value: 'eligibilityPeople',
        labelKey: 'EligibilityPeople',
        display: true,
        requireAccess:
            'ResourceAdmin-Applications-Details-Overview-EligibilityPeople',
    },
    {
        value: 'localSensitiveFunctions',
        labelKey: 'SensitiveFunctions',
        display: false,
    },
    {
        value: 'localRights',
        labelKey: 'Rights',
        display: false,
    },

    {
        value: 'applications',
        labelKey: 'Applications',
        display: false,
    },
]

export const Overview = (props) => {
    const classes = useStyles()
    const isSmallScreen = useIsSmallScreenFalse()

    const { applicationId } = props

    const { data, isLoading } = useApplication(applicationId)
    const { list: locations } = useDirectAssignedLocations(applicationId)

    const policy = useAccessRequestPolicy(data && data.requestPolicyId)

    const { t } = useTranslation()

    const { trimControlsBasedOnAccess, hasAccessToControl } = useSubcomponents()
    const sections = trimControlsBasedOnAccess(allSections).map((f) => f.name)
    const subTabsAccess = trimControlsBasedOnAccess(
        subTabs.filter((t) => t.display),
    )
    const [subActiveTab, setSubActiveTab] = useState(
        subTabsAccess?.length > 0 ? subTabsAccess[0].value : null,
    )
    const isAzureApplication = isApplicationAzureApp(data?.applicationType)

    const isPBACApplication = isApplicationPbacApp(data?.applicationType)

    const isAzureWithPBACApplication = isApplicationAzureAppWithPbac(
        data?.applicationType,
    )

    const azureApplicationDetails = data && (
        <Box>
            <Attribute>
                <AttributeLabel
                    className={classes.azureApplicationDetailsLabel}
                >
                    {t('AzureApplicationDetails')}
                </AttributeLabel>
            </Attribute>
            <Box display="flex" flexWrap="wrap">
                <Box paddingRight="32px">
                    {data.resourceSystemFriendlyName && (
                        <Attribute>
                            <AttributeLabel>
                                {t('ResourceSystem')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.resourceSystemFriendlyName}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureIntegrationType && (
                        <Attribute>
                            <AttributeLabel>
                                {t('TypeOfIntegration')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureIntegrationType}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureApplicationLine && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Application_Line')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureApplicationLine}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureUpxId && (
                        <Attribute>
                            <AttributeLabel>{t('Upx_ID')}</AttributeLabel>
                            <AttributeValue
                                className={
                                    classes.azureApplicationDetailsIdValues
                                }
                            >
                                {data.azureUpxId}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureAuthenticationLevel && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Authentication_Level')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureAuthenticationLevel}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.applicationId && (
                        <Attribute>
                            <AttributeLabel>
                                {t('AzureApplicationAppId')}
                            </AttributeLabel>
                            <AttributeValue
                                className={
                                    classes.azureApplicationDetailsIdValues
                                }
                            >
                                {data.applicationId}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureApplicationId && (
                        <Attribute>
                            <AttributeLabel>
                                {t('AzureApplicationObjectId')}
                            </AttributeLabel>
                            <AttributeValue
                                className={
                                    classes.azureApplicationDetailsIdValues
                                }
                            >
                                {data.azureApplicationId}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.systemIdentifier && isAzureWithPBACApplication && (
                        <Attribute>
                            <AttributeLabel>
                                {t('SystemIdentifier')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.systemIdentifier}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.servicePrincipleId && (
                        <Attribute>
                            <AttributeLabel>
                                {t('ServicePrincipleId')}
                            </AttributeLabel>
                            <AttributeValue
                                className={
                                    classes.azureApplicationDetailsIdValues
                                }
                            >
                                {data.servicePrincipleId}
                            </AttributeValue>
                        </Attribute>
                    )}
                </Box>
            </Box>
        </Box>
    )

    const applicationDetails = (
        <Fragment>
            <Box className={classes.basicInfo}>
                <Attribute>
                    <AttributeLabel>{t('FriendlyName')}</AttributeLabel>
                    <AttributeValue>{data?.friendlyName}</AttributeValue>
                </Attribute>

                <Attribute>
                    <AttributeLabel>{t('TechnicalName')}</AttributeLabel>
                    <AttributeValue>{data?.name}</AttributeValue>
                </Attribute>

                <Attribute>
                    <AttributeLabel>{t('Description')}</AttributeLabel>
                    <AttributeValue>{data?.description}</AttributeValue>
                </Attribute>

                <Attribute>
                    <AttributeLabel>{t('PublishInITShop')}</AttributeLabel>
                    <AttributeValue>
                        {data?.requestable ? t('Yes') : t('No')}
                    </AttributeValue>
                </Attribute>

                <Attribute>
                    <AttributeLabel>
                        {t('ApplicationResourceId')}
                    </AttributeLabel>
                    <AttributeValue>{data?.resourceId}</AttributeValue>
                </Attribute>
                <Attribute>
                    <AttributeLabel>
                        {t('ApplicationAuthorizationModel')}
                    </AttributeLabel>
                    <AttributeValue>
                        {
                            data?.protectedApplicationResourceUsageTypeFriendlyName
                        }
                    </AttributeValue>
                </Attribute>
            </Box>
        </Fragment>
    )

    const showApplicationDetails = sections.includes('applicationDetails')
    const showAzureApplicationDetails =
        (sections.includes('azureApplicationDetails') &&
            data &&
            isAzureApplication) ||
        isAzureWithPBACApplication

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>
                {showApplicationDetails && (
                    <Box
                        data-protectedsubcomponent={
                            'ResourceAdmin-ApplicationDetails-GeneralDetails-Column'
                        }
                        className={classes.section}
                    >
                        {applicationDetails}
                    </Box>
                )}
                {showApplicationDetails && !isAzureApplication && (
                    <Box className={classes.section}>
                        <Attribute>
                            <AttributeLabel>
                                {t('OAuthProviderAppId')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data?.oAuthProviderApplicationId}
                            </AttributeValue>
                        </Attribute>

                        <Attribute>
                            <AttributeLabel>
                                {t('ResourceSystem')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data?.resourceSystemFriendlyName}
                            </AttributeValue>
                        </Attribute>
                        <Attribute>
                            <AttributeLabel>
                                {t('DirectAssignedLocations')}
                            </AttributeLabel>

                            <AttributeValue>
                                {locations &&
                                    convertListToFriendlyNameCsv(locations)}
                            </AttributeValue>
                        </Attribute>
                        <Attribute>
                            <AttributeLabel>
                                {t('AccessRequestPolicy')}
                            </AttributeLabel>
                            <AttributeValue>{policy?.name}</AttributeValue>
                        </Attribute>

                        <Attribute>
                            <AttributeLabel>
                                {t('AllowsTimeConstrainedAccess')}
                            </AttributeLabel>
                            <AttributeValue>
                                {policy?.isTimeConstrained ? t('Yes') : t('No')}
                            </AttributeValue>
                        </Attribute>

                        {isPBACApplication && (
                            <>
                                <Attribute>
                                    <AttributeLabel>
                                        {t('AllowShopForRights')}
                                    </AttributeLabel>
                                    <AttributeValue>
                                        {data?.allowShopForRights
                                            ? t('Yes')
                                            : t('No')}
                                    </AttributeValue>
                                </Attribute>

                                <Attribute>
                                    <AttributeLabel>
                                        {t('AllowShopForRoleDefinitions')}
                                    </AttributeLabel>
                                    <AttributeValue>
                                        {data?.allowShopForRoleDefinitions
                                            ? t('Yes')
                                            : t('No')}
                                    </AttributeValue>
                                </Attribute>

                                <Attribute>
                                    <AttributeLabel>
                                        {t('AllowShopForAppManagementRoles')}
                                    </AttributeLabel>
                                    <AttributeValue>
                                        {data?.allowShopForAppManagementRoles
                                            ? t('Yes')
                                            : t('No')}
                                    </AttributeValue>
                                </Attribute>
                            </>
                        )}
                    </Box>
                )}

                {showAzureApplicationDetails && (
                    <Box
                        data-protectedsubcomponent={
                            'ResourceAdmin-AzureApplicationDetails-GeneralDetails-Column'
                        }
                        className={classes.section}
                    >
                        {azureApplicationDetails}
                    </Box>
                )}

                {data && isAzureApplication && (
                    <Box className={classes.section}>
                        {hasAccessToControl(canSeeOwnersControlName) && (
                            <Box
                                data-protectedsubcomponent={
                                    canSeeOwnersControlName
                                }
                            >
                                <Attribute>
                                    <AttributeLabel>
                                        {t('Applications_Owners')}
                                    </AttributeLabel>
                                    <AzureApplicationOwners
                                        pageSize={3}
                                        data={data}
                                    />
                                </Attribute>
                            </Box>
                        )}
                        {hasAccessToControl(canSeeDeputiesControlName) && (
                            <Box
                                data-protectedsubcomponent={
                                    canSeeDeputiesControlName
                                }
                            >
                                <Attribute>
                                    <AttributeLabel>
                                        {t('Deputies')}
                                    </AttributeLabel>
                                    <AzureApplicationDeputies
                                        pageSize={3}
                                        data={data}
                                    />
                                </Attribute>
                            </Box>
                        )}
                    </Box>
                )}
                {data && !isAzureApplication && (
                    <Box className={classes.section}>
                        {hasAccessToControl(canSeeOwnersControlName) && (
                            <Box
                                data-protectedsubcomponent={
                                    canSeeOwnersControlName
                                }
                            >
                                <Attribute>
                                    <AttributeLabel>
                                        {t('Applications_Owners')}
                                    </AttributeLabel>
                                    <Owners applicationId={applicationId} />
                                </Attribute>
                            </Box>
                        )}
                        <Attribute>
                            <AttributeLabel>
                                {t('ResponsibleParty')}
                            </AttributeLabel>
                            {data?.ownerIsPerson ? (
                                <ResponsibleParty
                                    responsiblePersonId={data?.ownerId}
                                />
                            ) : (
                                <AttributeValue>
                                    {data?.ownerFriendlyName || '-'}
                                </AttributeValue>
                            )}
                        </Attribute>
                    </Box>
                )}
            </Box>
        </Fragment>
    )

    return (
        <>
            {isLoading ? (
                <Box width={'100%'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Box>
                    <Paper
                        className={classes.paperContainer}
                        style={{
                            padding: isSmallScreen ? '23px' : '32px',
                            paddingBottom: 0,
                        }}
                    >
                        {renderContent()}
                        {data && (
                            <>
                                {subTabsAccess.length > 0 && (
                                    <Box
                                        style={{
                                            marginLeft: '-18px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            gap: 15,
                                        }}
                                    >
                                        <ItemDetails.Tabs
                                            value={subActiveTab}
                                            variant={'scrollable'}
                                            style={{ border: 'none' }}
                                            onChange={(_, value) => {
                                                setSubActiveTab(value)
                                            }}
                                        >
                                            {subTabsAccess.map((tab, index) => {
                                                return (
                                                    <ItemDetails.Tabs.Tab
                                                        data-protectedsubcomponent={
                                                            subTabsAccess.find(
                                                                (x) =>
                                                                    x.value ===
                                                                    tab.value,
                                                            )?.requireAccess
                                                        }
                                                        key={tab.value + index}
                                                        value={tab.value}
                                                        label={
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    flexDirection:
                                                                        'column',
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        marginTop: 5,
                                                                        marginRight: 5,
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    {t(
                                                                        tab.labelKey,
                                                                    )}
                                                                </span>
                                                            </div>
                                                        }
                                                    />
                                                )
                                            })}
                                        </ItemDetails.Tabs>
                                    </Box>
                                )}
                            </>
                        )}
                    </Paper>
                    {subActiveTab === 'eligibility' && data && (
                        <PageContextProvider key="RequestViewApplicationsEligbility">
                            {data && (
                                <Eligibility
                                    roleId={data?.id}
                                    resourceTypeId={data?.resourceTypeId}
                                    resourceId={data?.resourceId}
                                    isAzureApplication={
                                        isAzureApplication ||
                                        isAzureWithPBACApplication
                                    }
                                />
                            )}
                        </PageContextProvider>
                    )}
                    {subActiveTab === 'eligibilityPeople' && data && (
                        <PageContextProvider key="RequestViewApplicationsEligbilityPeople">
                            {data && <EligibilityPeople roleId={data?.id} />}
                        </PageContextProvider>
                    )}
                </Box>
            )}
        </>
    )
}
