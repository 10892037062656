import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Drawer, Loader } from 'packages/eid-ui'
import { useIsSmallScreenFalse } from 'packages/core'
import StickyContainer from 'components/StickyContainer'
import { useApplicationSettings } from 'hooks'
import { TextFormatter, Button } from 'components'
import bgHeader from '../../../../../assets/images/bgHeader.png'
import FieldTypeValueDetails from './FieldTypeValueDetails'
import { useEffect } from 'react'
import { useAppState } from 'appContext'
import { RESOURCE_TYPE_NAMES } from 'utils'

const HeaderContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 4.8rem 0 3.2rem',
    overflow: 'hidden',
    color: '#ffffff',
    fontSize: '2.2rem',
})

const FieldTypeValueDrawer = ({ assigneePerson, onClose }) => {
    const { data: appConfig } = useApplicationSettings()
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()
    const [, dispatch] = useAppState()
    useEffect(() => {
        if (assigneePerson?.assigneeId) {
            dispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: {
                    targetAssigneeId: assigneePerson?.localRightId,
                    targetAssigneeFriendlyName: assigneePerson?.appRightFriendlyName,
                    targetAssignee_ResourceTypeFriendlyName: t(RESOURCE_TYPE_NAMES.LOCALRIGHT),
                },
            })

            dispatch({
                type: 'SET_TARGET_RESOURCE',
                payload: {
                    targetAssigneeId: assigneePerson?.localRightId,
                    targetAssigneeFriendlyName: assigneePerson?.appRightFriendlyName,
                    targetAssignee_ResourceTypeFriendlyName: t(RESOURCE_TYPE_NAMES.LOCALRIGHT),
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assigneePerson?.assigneeId])

    if (isSmallScreen) {
        return (
            <Box>
                <StickyContainer
                    color="#ffffff"
                    styles={{
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Box paddingY="10px" width="100%" maxWidth="260px">
                            <Button.BackButton onClick={() => onClose()}>
                                {t('BackToFieldTypeValues')}
                            </Button.BackButton>
                        </Box>

                        <HeaderContainer
                            height="80px"
                            textAlign="start"
                            bgcolor={appConfig.style.colors.primary?.header}
                            style={{
                                backgroundImage: `url(${bgHeader})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            <TextFormatter
                                value={
                                    assigneePerson?.appRight ??
                                    assigneePerson?.description
                                }
                                maxCharacters={75}
                            />
                        </HeaderContainer>
                    </Box>
                </StickyContainer>
                {!assigneePerson && <Loader />}
                {assigneePerson && (
                    <FieldTypeValueDetails
                        assigneePerson={assigneePerson}
                        isRole={false}
                        keyForRoleRightId="localRightId"
                        titleKey="appRight"
                    />
                )}
            </Box>
        )
    } else {
        return (
            <Drawer
                width="67%"
                top={50}
                bgColor="#fbfbfd"
                open={true}
                toggleDrawer={() => onClose()}
                stickyHeader
                header={
                    <HeaderContainer
                        height="95px"
                        bgcolor={appConfig.style.colors.primary?.header}
                        style={{
                            backgroundImage: `url(${bgHeader})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                        }}
                    >
                        <TextFormatter
                            value={
                                assigneePerson?.appRight ??
                                assigneePerson?.description
                            }
                            maxCharacters={385}
                            style={{ textAlign: 'start', fontSize: '22px' }}
                        />
                    </HeaderContainer>
                }
                closeOnOutsideClick
            >
                {!assigneePerson && <Loader />}
                {assigneePerson && (
                    <FieldTypeValueDetails
                        assigneePerson={assigneePerson}
                        isRole={false}
                        keyForRoleRightId="localRightId"
                        titleKey="appRight"
                    />
                )}
            </Drawer>
        )
    }
}

export default FieldTypeValueDrawer
