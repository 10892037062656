import { Box, Typography, styled, Dialog, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Button, PolicyDetails } from 'components'
import {
    Loader,
    Pagination,
    Select,
    ToggleSwitch,
    Tooltip,
} from 'packages/eid-ui'
import { ArrowSmallIcon, Icon } from 'packages/eid-icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Search from 'components/Filters/Search'
import {
    useAccessRequestPolicy,
    useAddItemsToCart,
    useCart,
    useConfigSettings,
    useIAmShopAssignees,
    useModalSearch,
} from 'hooks'
import { isNilOrEmpty, useDebounce } from 'packages/core'
import classNames from 'classnames'
import cartHelpers from 'containers/Cart/cartHelpers'
import { PageContextProvider, usePageContext } from 'pageContext'
import { ASSIGNMENT_TYPES, paginate } from 'utils'
import { EndDateTimePicker } from 'components/TimeConstrainedControl/EndDateTimePicker'
import { StartDateTimePicker } from 'components/TimeConstrainedControl/StartDateTimePicker'
import { useAppState } from 'appContext'
import AssignmentItem from './AssignmentItem'
import { PillTabs } from 'components/PillTabs'
import { Tab } from 'components/PillTabs/Tab'
import { TabLabel } from 'components/PillTabs/TabLabel'

export const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        right: '0rem',
        cursor: 'pointer',
        marginTop: '-1.2rem',
    },
})

const AssignmentsModal = styled(Box)({
    maxWidth: '70rem',
    width: '100%',
    background: '#fff !important',
    border: 'solid 0.1rem #ebebed',

    '& h2': {
        fontSize: '2.4rem',
        color: '#282828',
    },
})

const showCount = false

export const Divider = styled(Box)({
    background: '#d8d8d8 !important',
    height: '0.1rem',
    margin: '2.4rem 0',
})
export const InputBox = styled(Box)(({ theme }) => ({
    '& input': {
        boxShadow: '0 0.4rem 0.6rem -0.2rem rgba(0, 0, 0, 0.1)',
        border: 'solid 0.1rem #ebebed',
        height: '3rem',
        width: '100%',
        borderRadius: '0.5rem',
        outline: 'none',
        padding: '0.5rem 1.5rem',
    },
    '& .MuiSelect-select.MuiSelect-select': {
        width: '100%',
        boxShadow: '0 0.4rem 0.6rem -0.2rem rgba(0, 0, 0, 0.1)',
        border: 'solid 0.1rem #ebebed',
        height: '3rem',
        borderRadius: '0.5rem',
        outline: 'none',
        padding: '0.5rem 1.5rem',
        background: `${theme?.palette.common.white} !important`,
    },

    '& .MuiInputBase-fullWidth': {
        maxWidth: '36rem',
    },
    '& MuiSelect-selectMenu': {
        fontSize: '1.4rem',
    },

    '& .MuiInput-underline': {
        '&:after': {
            border: 'none !important',
        },
        '&:before': {
            border: 'none !important',
        },
    },
}))
export const ModalFooter = styled(Box)(({ theme }) => ({
    display: 'flex',
    '& button': {
        border: `solid 0.1rem ${theme?.palette?.primary?.main}`,
        padding: '0.4rem 1.6rem 0.4rem 1.3rem',
        width: '100%',
        borderRadius: '0.4rem',
        outline: 'none',
        height: '3.2rem',
        fontSize: '1.4rem',
        '& svg': {
            marginLeft: '0.8rem',
        },
        '&:nth-child(1)': {
            background: theme?.palette?.primary?.main,
            color: theme?.palette.common.white,
            maxWidth: '24rem',
        },
        '&:nth-child(2)': {
            color: theme?.palette?.primary?.main,
            marginLeft: '1.6rem',
            maxWidth: '9rem',
        },
    },
}))

export const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '83rem',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '83rem',
            width: '100%',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: '#f7f8fa',
        position: 'relative',
    },
})

const useStyles = makeStyles((theme) => ({
    headerBox: {
        padding: '3.2rem 2rem 3.2rem 4rem',
        backgroundColor: `${theme?.palette.common.white} !important`,
        maxHeight: '8.4rem',
    },
    bodyBox: {
        padding: '2.4rem 3.2rem 3.2rem 3.2rem',
    },

    toggleSwitch: {
        '& .MuiFormControlLabel-label': {
            wordBreak: 'break-word',
        },
    },

    buttonRemoveSection: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        marginTop: '4rem',
        '& button': {
            display: 'flex',
            justifyContent: 'center',
            height: 'fit-content !important',
            gap: '1rem', // Converted from 10px to 1rem
            borderRadius: '0.5rem', // Converted from 5px to 0.5rem
            '& label': {
                maxWidth: '85%',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                wordWrap: 'break-word',
                cursor: 'pointer',
            },
        },
    },
    searchPeople: {
        marginBottom: '2.4rem',
        '& p': {
            fontSize: '1.6rem',
            marginBottom: '1.6rem',
        },
        '& svg': {
            marginLeft: '0 !important',
        },
    },
    timeConstraintSwitchButton: {
        backgroundColor: '#fbfbfc',
        color: '#4a4a4a !important',
        border: 'solid 0.1rem #b2b5bb', // Converted from 1px to 0.1rem
        borderRadius: '0.5rem', // Converted from 5px to 0.5rem
        padding: '1.6rem', // Converted from 16px to 1.6rem
        minHeight: '5.6rem', // Converted from 56px to 5.6rem
        '&:hover': {
            backgroundColor: '#fbfbfc',
            border: 'solid 0.1rem #265fb4', // Converted from 1px to 0.1rem
        },
        '& span': {
            fontWeight: 'bold',
        },
    },

    activeTimeConstraintSwitchButton: {
        color: `${theme?.palette.common.black} !important`,
        border: 'solid 0.1rem #265fb4',
        '&:hover': {
            color: `${theme?.palette.common.black} !important`,
        },
    },
    disabledTimeConstraintSwitchButton: {
        color: 'rgba(0, 0, 0, 0.26) !important',
    },
    searchBar: {
        width: '26rem !important',
        boxShadow: '0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.1)', // Converted from 2px, 8px, and 0.1 to 0.2rem, 0.8rem, and 0.01rem respectively
        '& div': {
            borderRadius: '0.4rem', // Converted from 4px to 0.4rem
        },
    },
    inactiveButtonForAdd: {
        border: `solid 0.1rem ${theme?.palette.grey['900']}`, // 1px = 0.1rem
        color: `${theme?.palette.grey['900']}`,
        marginTop: '6rem',
        marginBottom: '2rem',
        '& svg': {
            width: '1.2rem', // Already in rem
            position: 'absolute',
            right: '0.5rem', // 5px = 0.5rem
        },
    },

    activeButtonForAdd: {
        backgroundColor: theme?.palette.secondary.main,
        color: `${theme?.palette.common.white} !important`,
        borderColor: `${theme?.palette.common.white} !important`,
        '&:hover': {
            backgroundColor: '#01ae8fcc !important',
        },
        '& svg': {
            color: `${theme?.palette.common.white} !important`,
            fill: `${theme?.palette.common.white} !important`,
        },
    },

    inactiveButtonForRemove: {
        border: `solid 0.1rem ${theme?.palette.grey['900']}`,
        color: `${theme?.palette.grey['900']}`,
        marginBottom: '2rem',
        '& svg': {
            width: '1.2rem',
            position: 'absolute',
            left: '0.5rem',
        },
    },
    activeButtonForRemove: {
        color: `${theme?.palette.error.main} !important`,
        borderColor: `${theme?.palette.error.main} !important `,

        '& svg': {
            color: `${theme?.palette.error.main} !important`,
            fill: `${theme?.palette.error.main} !important`,
        },
    },

    inactiveButtonForRemoveAll: {
        border: `solid 0.1rem ${theme?.palette.grey['900']}`,
        color: theme?.palette.grey['900'],
        '& svg': {
            width: '1.2rem',
            position: 'absolute',
            left: '0.5rem',
        },
    },
    activeButtonForRemoveAll: {
        border: `solid 0.1rem ${theme?.palette.error.main} !important`,
        color: `${theme?.palette.error.main} !important`,
        '& svg': {
            color: `${theme?.palette.error.main} !important`,
            fill: `${theme?.palette.error.main} !important`,
        },
    },
    timeSectionFooter: {
        marginTop: '3.2rem',
        '& button': {
            maxWidth: '100% !important',
            width: 'fit-content',
        },
    },
    selectAssignmentSectionContainer: {
        background: `${theme?.palette.common.white} !important`,
        border: 'solid 0.1rem #ebebed  !important',
        borderRadius: '0.5rem !important',
        width: '26rem !important',
        borderTop: 'none !important',
    },

    assignmentsModalLoader: {
        background: `${theme?.palette.common.white} !important`,
        height: '100%',
        '& .MuiBox-root': {
            background: `${theme?.palette.common.white} !important`,
        },
    },

    tabsContainer: {
        display: 'flex',
        width: '101% !important',
        marginBottom: '-0.3rem',
        marginLeft: '-0.1rem',
        zIndex: 999,
    },
}))

const SelectAssignmentSection = styled(Box)(({ theme }) => ({
    background: `${theme?.palette.common.white} !important`,
    width: '26rem !important',
    overflow: 'hidden',
    height: '37.6rem',
    overflowY: 'auto',
    padding: '0.5rem',
    '&::-webkit-scrollbar ': {
        display: 'none',
    },
}))
const PaginationMain = styled(Box)({
    width: '26rem !important',
    marginTop: '1.5rem',
    '& div:first-child': {
        justifyContent: 'center',
    },
    '& .MuiBox-root': {
        padding: '0 !important',
    },
    '& ul': {
        height: '3rem',
        borderRadius: '2rem',
        flexWrap: 'nowrap !important',
        '& li': {
            '& button': {
                padding: '0 0.2rem',
            },
        },
    },
})
const ButtonSection = styled(Box)({
    marginLeft: '1.5rem', // 15px = 1.5rem
    marginRight: '1.5rem', // 15px = 1.5rem
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '25rem !important',
    gap: '10rem', // 100px = 10rem
    '& button': {
        minWidth: '12.5rem',
        cursor: 'pointer',
        fontSize: '1.4rem', // 14px = 1.4rem
        borderRadius: '0.4rem',
        height: '3.1rem',
    },
})
//Below page size is for Client Side pagination
const pageSize = 6

export const PermissionLevels = ({
    value,
    permissionLevelProps,
    onChange,
    targetAssigneeId,
}) => {
    const { t } = useTranslation()

    const { list: accessLevels } = useIAmShopAssignees(targetAssigneeId, {
        take: 10000,
    })
    const { permissionLabelProp } = permissionLevelProps
    const getOptionLabel = (option) => option[permissionLabelProp]

    return (
        <InputBox>
            <Typography variant="subtitle1">
                {t('IAmShopPermissionLevel')}
            </Typography>
            <Select
                value={value}
                options={accessLevels?.length > 0 ? accessLevels : []}
                placeholder={t('GetPermissionLevel')}
                getOptionLabel={getOptionLabel}
                onChange={onChange}
                required
            />
        </InputBox>
    )
}
const AddNewAssignment = ({
    searchUrl,
    identifier = 'id',
    labelKey,
    showPermissionLevel,
    filterWithAccessLevel = false,
    targetAssigneeResource,
    targetAssigneeId,
    startIcon,
    headerText,
    queryParams = {},
    // We have now fixed labels but not removing it from prop, maybe required in future
    listLabels = {
        unselectedListLabel: 'All',
        selectedListLabel: 'Added',
    },
    searchPlaceHolder = 'SearchFilterMembers',
    submitButtonLabelKey,
    hideDurationSelection = false,
    permissionLevelProps = {
        permissionLabelProp: 'value',
        permissionIdetifierProp: 'id',
    },
    handleClose = null,
    showAssignmentModal = false,
    secondaryAttributes = ['email'],
    resourceTypeRoleId_ConfigName,
    showAccessRequestPolicy = false,
    requestPolicyId,
    requestPolicyIdProperty = 'accessRequestPolicyId',
    fetchRequestPolicyItemWise = false,
    showTimeConstrainedFilter = false,
    showStartDate = true,
    assignmentType = ASSIGNMENT_TYPES.ADD,
}) => {
    const { t } = useTranslation()
    const classes = useStyles({})
    const theme = useTheme()
    const [addItemsToCart, { isLoading }] = useAddItemsToCart()
    const [, dispatch] = usePageContext()
    const { data: cart } = useCart()
    const [{ devFeaturesEnabled }] = useAppState()

    const [openAssigneeModal, setOpenAssigneeModal] =
        useState(showAssignmentModal)
    const [poilicyAssignee, setPolicyAssignee] = useState(null)
    const [timeConstrained, setTimeConstrained] = useState(false) //NOTE: In cases where we are hiding time selection switch, we set the time constrained to be false
    const [searchKey, setSearchKey] = useState('')
    const debouncedSearchValue = useDebounce(searchKey)
    const [peopleAddedList, setPeopleAddedList] = useState([])
    const [selectedAddedAssignments, setSelectedAddedAssignments] = useState([])
    const [selectedAssignments, setSelectedAssignments] = useState([])
    const currentDatetime = moment.utc().second(0).format()

    const queryData = {
        searchTerm: debouncedSearchValue,
        take: pageSize,
        accessLevelId:
            showPermissionLevel && filterWithAccessLevel
                ? poilicyAssignee?.id
                : undefined,
        ...queryParams,
    }
    const { list, loading, pagination, totalCount } = useModalSearch(
        searchUrl,
        queryData,
        filterWithAccessLevel
            ? Boolean(poilicyAssignee?.id)
            : openAssigneeModal,
        showCount,
    )

    const [
        getAddAssignmentConfiguration,
        { data: configSettings, isLoading: configSettingsLoading },
    ] = useConfigSettings(resourceTypeRoleId_ConfigName)

    useEffect(() => {
        if (Boolean(resourceTypeRoleId_ConfigName) && openAssigneeModal) {
            getAddAssignmentConfiguration(resourceTypeRoleId_ConfigName)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourceTypeRoleId_ConfigName, openAssigneeModal])

    //Pagination for Right side items
    const [page_RightSide, setPage_RightSide] = useState(1)
    const paginatedData_RightSide = paginate(
        peopleAddedList,
        page_RightSide,
        pageSize,
    )
    const numberOfPages_RightSide = peopleAddedList
        ? Math.ceil(peopleAddedList?.length / pageSize)
        : 0

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }
    const [{ selectedStartDate, selectedEndDate }, setSelectedDate] = useState({
        selectedStartDate: currentDatetime,
        selectedEndDate: null,
    })

    const allowMultipleSelection = !fetchRequestPolicyItemWise //NOTE: if have to fetch request policy of each selected item of left side, we cant allow multiple selection on left side

    const policyId = requestPolicyId
        ? requestPolicyId
        : fetchRequestPolicyItemWise
        ? selectedAssignments[0]?.[requestPolicyIdProperty]
        : ''

    const policy = useAccessRequestPolicy(
        showAccessRequestPolicy ? policyId : '',
    )

    useEffect(() => {
        if (policy?.isTimeConstrained) {
            setTimeConstrained(true)
        }
    }, [policy])

    const violatesPolicy =
        policy?.maximumValueInMinutes &&
        moment(selectedEndDate).diff(moment(selectedStartDate), 'minutes') >
            policy?.maximumValueInMinutes

    const hasInvalidDates = timeConstrained
        ? isNilOrEmpty(selectedStartDate) ||
          isNilOrEmpty(selectedEndDate) ||
          violatesPolicy ||
          moment.utc(selectedStartDate).isAfter(moment.utc(selectedEndDate))
        : false

    let invalidDatesMessage = ''

    if (isNilOrEmpty(selectedStartDate)) {
        invalidDatesMessage = t('InvalidStartDateTime')
    }
    if (isNilOrEmpty(selectedEndDate)) {
        invalidDatesMessage = t('InvalidEndDateTime')
    }
    if (moment.utc(selectedStartDate).isAfter(moment.utc(selectedEndDate))) {
        invalidDatesMessage = t('EndDateGreaterThanStartDate')
    }
    if (violatesPolicy) {
        invalidDatesMessage = t('PolicyAllowsMaxDurationInMinError', {
            count: policy?.maximumValueInMinutes,
        })
    }

    const handleStartDate = (startDateToSet) => {
        setSelectedDate((prev) => {
            return { ...prev, selectedStartDate: startDateToSet }
        })
    }

    const handleEndDate = (endDateToSet) => {
        setSelectedDate((prev) => {
            return { ...prev, selectedEndDate: endDateToSet }
        })
    }

    const handlePolicyAssigneChange = (evt) => {
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
        setPolicyAssignee(evt)
    }

    const handleModalClose = () => {
        setOpenAssigneeModal(false)
        setSearchKey('')
        setSelectedAssignments([])
        setPeopleAddedList([])
        setSelectedAddedAssignments([])
        setPolicyAssignee(null)
        setTimeConstrained(false)
        setSelectedDate({
            selectedStartDate: currentDatetime,
            selectedEndDate: null,
        })
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
        if (handleClose) {
            handleClose()
        }
    }

    const checkIfSelected = (item) => {
        let selectedAttribute = {
            isSelected: false,
            alreadyAdded: false,
        }
        if (checkIfAlreadyAddedToSelectedList(item)) {
            selectedAttribute.alreadyAdded = true
        }
        if (isSelectedToAdd(item, selectedAssignments)) {
            selectedAttribute.isSelected = true
        }
        return selectedAttribute
    }

    const handleSelectPeople = (item) => {
        // Check if already added
        if (
            checkIfAlreadyAddedToSelectedList(item) ||
            checkIfAlreadyAddedToCart(item)
        ) {
            return
        }
        // If first selection add to list only
        if (!selectedAssignments?.length) {
            setSelectedAssignments([item])
            return
        }
        if (!allowMultipleSelection) {
            setSelectedAssignments([item])
            return
        }

        //  Check if item already added then update list
        setSelectedAssignments(
            addRemoveOnSelectionFromList(item, selectedAssignments),
        )
    }

    const isSelectedToAdd = (item, list) => {
        return list.find((x) => x[identifier] === item[identifier])
            ? true
            : false
    }

    const checkIfAlreadyAddedToSelectedList = (item) => {
        return peopleAddedList.find((x) => x[identifier] === item[identifier])
            ? true
            : false
    }

    const handleAddSelectedPeople = () => {
        if (selectedAssignments.length > 0) {
            selectedAssignments.forEach((x) => {
                x.timeConstrained = timeConstrained
                x.startDateUtc = timeConstrained ? selectedStartDate : null
                x.endDateUtc = timeConstrained ? selectedEndDate : null
            })
            setPeopleAddedList((pre) => [...selectedAssignments, ...pre])
            setPage_RightSide(1)
            setSelectedAssignments([])
        }
    }

    const checkIfAddedSelected = (item) => {
        let selectedAttribute = {
            isSelected: false,
            alreadyAdded: false,
        }
        if (isSelectedToAdd(item, selectedAddedAssignments)) {
            selectedAttribute.isSelected = true
        }
        return selectedAttribute
    }

    const handleAddedPeopleChange = (item) => {
        if (item?.isInherited && item?.isAssigned) {
            return
        }
        setSelectedAddedAssignments(
            addRemoveOnSelectionFromList(item, selectedAddedAssignments),
        )
    }

    const addRemoveOnSelectionFromList = (item, list) => {
        let selectedValues = [...list]
        const memberIndex = selectedValues.findIndex(
            (x) => x[identifier] === item[identifier],
        )
        if (memberIndex < 0) {
            selectedValues.push(item)
        } else {
            selectedValues.splice(memberIndex, 1)
        }

        return selectedValues
    }

    const handleRemove = (actionType) => {
        if (actionType === 'all') {
            setPeopleAddedList([])
            setSelectedAddedAssignments([])
        } else {
            const listAfterSelectedRemoved = peopleAddedList.filter((item) => {
                const isItemSelected = selectedAddedAssignments.find(
                    (x) => x[identifier] === item[identifier],
                )
                if (!isItemSelected) {
                    return item
                }
            })
            setPeopleAddedList(listAfterSelectedRemoved)
            setSelectedAddedAssignments([])
        }
    }

    const postAdd = () => {
        handleModalClose()
    }

    const preAdd = () => {
        if (peopleAddedList.length > 0) {
            let itemsToAdd = []
            peopleAddedList.forEach((item) => {
                let itemToAdd = cartHelpers.assignResourceToAssignee({
                    assignmentType,
                    targetAssigneeId: targetAssigneeId,
                    selectedList: [item],
                    timeConstrained: item.timeConstrained,
                    resource: targetAssigneeResource,
                    startDateUtc: item.startDateUtc,
                    endDateUtc: item.endDateUtc,
                    resourceTypeFriendlyName: item?.resourceTypeFriendlyName,
                    identifier,
                    resourceTypeRoleId: resourceTypeRoleId_ConfigName
                        ? configSettings?.value
                        : poilicyAssignee?.id,
                })
                itemsToAdd.push(itemToAdd[0])
            })

            if (!itemsToAdd) return undefined
            return itemsToAdd
        }
    }

    const handleAddAssignments = () => {
        const itemToAdd = preAdd()
        if (itemToAdd === undefined) return
        addItemsToCart(itemToAdd).then(() => postAdd())
    }

    const checkIfAlreadyAddedToCart = (item) => {
        return cartHelpers.isItemAlreadyInCart(cart, item[identifier])
    }
    const getTooltipTitle = () => {
        if (showPermissionLevel && !poilicyAssignee) {
            return t('PermissionLevelRequired')
        }
        if (peopleAddedList.length <= 0) {
            return t('NoChangeInAssignment')
        }
        return ''
    }
    const hasTooltipValue = getTooltipTitle() !== '' || configSettingsLoading

    const handleDoubleClickForAdding = (e, item) => {
        if (hasInvalidDates) {
            return
        }
        if (
            checkIfAlreadyAddedToSelectedList(item) ||
            checkIfAlreadyAddedToCart(item)
        ) {
            return
        }
        item.timeConstrained = timeConstrained
        item.startDateUtc = timeConstrained ? selectedStartDate : null
        item.endDateUtc = timeConstrained ? selectedEndDate : null

        setPeopleAddedList([item, ...peopleAddedList])
        setPage_RightSide(1)
        setSelectedAddedAssignments((s) =>
            s.filter((el) => el[identifier] !== item[identifier]),
        )
    }
    const handleDoubleClickForRemoving = (e, item) => {
        if (item?.isInherited && item?.isAssigned) {
            return
        }
        const listAfterSelectedRemoved = peopleAddedList.filter((el) => {
            return item[identifier] !== el[identifier]
        })
        setPeopleAddedList(listAfterSelectedRemoved)
        setSelectedAddedAssignments((s) =>
            s.filter((el) => el[identifier] !== item[identifier]),
        )
    }

    return (
        <>
            {(labelKey || startIcon) && (
                <Button
                    onClick={() => setOpenAssigneeModal(true)}
                    style={{
                        borderRadius: '0.4rem', // 4px = 0.4rem
                        backgroundColor: theme?.palette?.primary?.main,
                        color: '#ffffff',
                        fontSize: '1.4rem', // 14px = 1.4rem

                        height: '100%',
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.main,
                        },
                    }}
                >
                    <Box style={{ display: 'flex' }}>
                        {startIcon}
                        {t(labelKey)}
                    </Box>
                </Button>
            )}
            <CustomDialog
                open={openAssigneeModal}
                onClose={() => handleModalClose()}
                title={t('')}
            >
                <AssignmentsModal>
                    <Box className={classes.headerBox}>
                        <CloseIconeBox onClick={() => handleModalClose()}>
                            <Icon
                                name="Close"
                                width="1.2rem" // 12px = 1.2rem
                                height="1.3rem" // 13px = 1.3rem
                                color="#959598"
                            />
                        </CloseIconeBox>

                        <Typography variant="h6">
                            {headerText ? (
                                <span>
                                    <strong>{headerText.dynamicText}</strong>
                                    {` - ${t(headerText.text)}`}
                                </span>
                            ) : (
                                <strong>{t(labelKey)}</strong>
                            )}
                        </Typography>
                    </Box>

                    <Box className={classes.bodyBox}>
                        <PageContextProvider key={'PermissionLevelSelection'}>
                            {showPermissionLevel && openAssigneeModal && (
                                <>
                                    <PermissionLevels
                                        value={poilicyAssignee}
                                        permissionLevelProps={
                                            permissionLevelProps
                                        }
                                        onChange={(e) =>
                                            handlePolicyAssigneChange(e)
                                        }
                                        targetAssigneeId={targetAssigneeId}
                                    />
                                    <Divider />
                                </>
                            )}
                        </PageContextProvider>
                        {showTimeConstrainedFilter && devFeaturesEnabled && (
                            <>
                                <Button
                                    onClick={
                                        () =>
                                            console.log('Filter permanent list') //TODO : Add when filtering functionality is available
                                    }
                                    disabled={policy?.isTimeConstrained}
                                    className={classNames(
                                        classes.timeConstraintSwitchButton,
                                        {
                                            [classes.activeTimeConstraintSwitchButton]:
                                                !timeConstrained,
                                            [classes.disabledTimeConstraintSwitchButton]:
                                                policy?.isTimeConstrained,
                                        },
                                    )}
                                    style={{
                                        marginRight: '1.6rem',
                                    }}
                                    startIcon={
                                        <Icon
                                            name="Clock"
                                            color={
                                                timeConstrained
                                                    ? theme?.palette.grey['900']
                                                    : theme?.palette.info.main
                                            }
                                        />
                                    }
                                >
                                    {t('Permanent')}
                                </Button>

                                <Button
                                    onClick={
                                        () =>
                                            console.log(
                                                'Filter time constrained list',
                                            ) //TODO : Add when filtering functionality is available
                                    }
                                    className={classNames(
                                        classes.timeConstraintSwitchButton,
                                        {
                                            [classes.activeTimeConstraintSwitchButton]:
                                                timeConstrained,
                                        },
                                    )}
                                    startIcon={
                                        <Icon
                                            name="Clock"
                                            color={
                                                !timeConstrained
                                                    ? theme?.palette.grey['900']
                                                    : theme?.palette.info.main
                                            }
                                        />
                                    }
                                >
                                    {t('TimeConstraint')}
                                </Button>

                                <Divider />
                            </>
                        )}

                        {(!showPermissionLevel || poilicyAssignee) && (
                            <>
                                <Box className={classes.searchPeople}>
                                    <Box className={classes.searchBar}>
                                        <Search
                                            handleSearch={handleSearchChange}
                                            placeholder={t(searchPlaceHolder)}
                                            value={searchKey}
                                            handleClear={handleClear}
                                            outlined
                                        />
                                    </Box>
                                </Box>

                                <Box display="flex" flex={'1'}>
                                    <Box
                                        className={
                                            classes.selectAssignmentSectionContainer
                                        }
                                    >
                                        <Box className={classes.tabsContainer}>
                                            <PillTabs
                                                variant={'standard'}
                                                value={'all'}
                                                onChange={() => {}}
                                            >
                                                <Tab
                                                    label={
                                                        <TabLabel
                                                            label={t(
                                                                listLabels.unselectedListLabel,
                                                            )}
                                                            count={totalCount}
                                                            countColor={
                                                                theme?.palette
                                                                    .primary
                                                                    .main
                                                            }
                                                        />
                                                    }
                                                    value={'all'}
                                                />
                                            </PillTabs>
                                        </Box>
                                        <Box>
                                            <SelectAssignmentSection>
                                                {loading && (
                                                    <Box
                                                        className={
                                                            classes.assignmentsModalLoader
                                                        }
                                                    >
                                                        <Loader />
                                                    </Box>
                                                )}

                                                {list?.length
                                                    ? list.map(
                                                          (item, index) => (
                                                              <AssignmentItem
                                                                  isAlreadyAddedToCart={checkIfAlreadyAddedToCart(
                                                                      item,
                                                                  )}
                                                                  handleSelectPeople={
                                                                      handleSelectPeople
                                                                  }
                                                                  isSelectedAttribute={checkIfSelected(
                                                                      item,
                                                                  )}
                                                                  item={item}
                                                                  index={index}
                                                                  handleDoubleClick={
                                                                      handleDoubleClickForAdding
                                                                  }
                                                                  isLeftSide={
                                                                      true
                                                                  }
                                                                  secondaryAttributes={
                                                                      secondaryAttributes
                                                                  }
                                                              />
                                                          ),
                                                      )
                                                    : !loading && <></>}
                                            </SelectAssignmentSection>
                                        </Box>
                                    </Box>

                                    <ButtonSection>
                                        <div
                                            className={
                                                classes.buttonRemoveSection
                                            }
                                        >
                                            {showAccessRequestPolicy &&
                                                policy && (
                                                    <PolicyDetails
                                                        policy={policy}
                                                        orientation="vertical"
                                                        showValuesIfUnrestricted={
                                                            false
                                                        }
                                                        styles={{
                                                            attributeLabel: {
                                                                minWidth:
                                                                    '19rem',
                                                            },
                                                        }}
                                                    />
                                                )}

                                            {!hideDurationSelection && (
                                                <Box
                                                    className={
                                                        classes.toggleSwitch
                                                    }
                                                >
                                                    <ToggleSwitch
                                                        value={timeConstrained}
                                                        label={t('SetDuration')}
                                                        labelColor="#5d6870"
                                                        disabled={
                                                            policy?.isTimeConstrained
                                                        }
                                                        onChange={() =>
                                                            setTimeConstrained(
                                                                (prev) => !prev,
                                                            )
                                                        }
                                                    />
                                                </Box>
                                            )}

                                            {timeConstrained && (
                                                <>
                                                    {showStartDate && (
                                                        <Box
                                                            padding={'0 0.4rem'}
                                                        >
                                                            <StartDateTimePicker
                                                                selectedStartDate={
                                                                    selectedStartDate
                                                                }
                                                                setSelectedStartDate={
                                                                    handleStartDate
                                                                }
                                                            />
                                                        </Box>
                                                    )}
                                                    <Box padding={'0 0.4rem'}>
                                                        <EndDateTimePicker
                                                            selectedEndDate={
                                                                selectedEndDate
                                                            }
                                                            setSelectedEndDate={
                                                                handleEndDate
                                                            }
                                                            minEndDate={moment(
                                                                selectedStartDate,
                                                            )}
                                                            maxEndDate={
                                                                policy?.isTimeConstrained
                                                                    ? moment(
                                                                          selectedStartDate,
                                                                      ).add(
                                                                          policy?.maximumValueInMinutes,
                                                                          'minute',
                                                                      )
                                                                    : null
                                                            }
                                                            hasInvalidDates={
                                                                hasInvalidDates
                                                            }
                                                            validationMessage={
                                                                invalidDatesMessage
                                                            }
                                                        />
                                                    </Box>
                                                </>
                                            )}
                                            <Button
                                                onClick={() =>
                                                    handleAddSelectedPeople()
                                                }
                                                disabled={
                                                    !selectedAssignments?.length ||
                                                    hasInvalidDates
                                                }
                                                className={classNames(
                                                    classes.inactiveButtonForAdd,
                                                    {
                                                        [classes.activeButtonForAdd]:
                                                            selectedAssignments?.length &&
                                                            !hasInvalidDates,
                                                    },
                                                )}
                                                style={{
                                                    marginTop:
                                                        showAccessRequestPolicy
                                                            ? '1.5rem !important'
                                                            : '6rem',
                                                }}
                                            >
                                                <label>{t('Add')}</label>

                                                <ArrowSmallIcon
                                                    color={
                                                        theme?.palette.grey[
                                                            '900'
                                                        ]
                                                    }
                                                    fill={
                                                        theme?.palette.grey[
                                                            '900'
                                                        ]
                                                    }
                                                    direction="right"
                                                />
                                            </Button>

                                            <Button
                                                onClick={() =>
                                                    handleRemove('selected')
                                                }
                                                disabled={
                                                    !selectedAddedAssignments?.length
                                                }
                                                className={classNames(
                                                    classes.inactiveButtonForRemove,
                                                    {
                                                        [classes.activeButtonForRemove]:
                                                            selectedAddedAssignments?.length,
                                                    },
                                                )}
                                            >
                                                <ArrowSmallIcon
                                                    color={
                                                        theme?.palette.grey[
                                                            '900'
                                                        ]
                                                    }
                                                    fill={
                                                        theme?.palette.grey[
                                                            '900'
                                                        ]
                                                    }
                                                    direction="left"
                                                />
                                                <label>{t('Remove')}</label>
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    handleRemove('all')
                                                }
                                                disabled={
                                                    !peopleAddedList?.length
                                                }
                                                className={classNames(
                                                    classes.inactiveButtonForRemoveAll,
                                                    {
                                                        [classes.activeButtonForRemoveAll]:
                                                            peopleAddedList?.length,
                                                    },
                                                )}
                                            >
                                                <Icon
                                                    style={{
                                                        fill: peopleAddedList.length
                                                            ? theme?.palette
                                                                  .error.main
                                                            : theme?.palette
                                                                  .grey['900'],
                                                    }}
                                                    direction={'left'}
                                                    name={'DoubleArrowSmall'}
                                                />
                                                <label>{t('RemoveAll')}</label>
                                            </Button>
                                        </div>
                                    </ButtonSection>

                                    <Box
                                        className={
                                            classes.selectAssignmentSectionContainer
                                        }
                                    >
                                        <Box className={classes.tabsContainer}>
                                            <PillTabs
                                                value={'selected'}
                                                onChange={() => {}}
                                            >
                                                <Tab
                                                    label={
                                                        <TabLabel
                                                            label={t(
                                                                listLabels.selectedListLabel,
                                                            )}
                                                            count={
                                                                peopleAddedList?.length
                                                            }
                                                            countColor={
                                                                theme?.palette
                                                                    .success
                                                                    .main
                                                            }
                                                        />
                                                    }
                                                    value={'selected'}
                                                />
                                            </PillTabs>
                                        </Box>
                                        <Box>
                                            <SelectAssignmentSection>
                                                {paginatedData_RightSide?.length
                                                    ? paginatedData_RightSide.map(
                                                          (item, index) => (
                                                              <AssignmentItem
                                                                  handleSelectPeople={
                                                                      handleAddedPeopleChange
                                                                  }
                                                                  isSelectedAttribute={checkIfAddedSelected(
                                                                      item,
                                                                  )}
                                                                  item={item}
                                                                  index={index}
                                                                  isRightSide={
                                                                      true
                                                                  }
                                                                  handleDoubleClick={
                                                                      handleDoubleClickForRemoving
                                                                  }
                                                                  secondaryAttributes={
                                                                      item?.timeConstrained
                                                                          ? showStartDate
                                                                              ? [
                                                                                    ...secondaryAttributes,
                                                                                    'startDateUtc',
                                                                                    'endDateUtc',
                                                                                ]
                                                                              : [
                                                                                    ...secondaryAttributes,
                                                                                    'endDateUtc',
                                                                                ]
                                                                          : secondaryAttributes
                                                                  }
                                                              />
                                                          ),
                                                      )
                                                    : null}
                                            </SelectAssignmentSection>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box display="flex">
                                    <PaginationMain>
                                        {pagination}
                                    </PaginationMain>
                                    <Box
                                        style={{
                                            maxWidth: '25rem',
                                            width: '100%',
                                        }}
                                    ></Box>
                                    {numberOfPages_RightSide > 1 && (
                                        <PaginationMain>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                width="100%"
                                                justifyContent="center"
                                                padding="5px 0px"
                                            >
                                                <Pagination
                                                    count={
                                                        numberOfPages_RightSide
                                                    }
                                                    size="large"
                                                    siblingCount={1}
                                                    page={page_RightSide}
                                                    onChange={(_, value) => {
                                                        setPage_RightSide(value)
                                                    }}
                                                    variant={'rounded'}
                                                />
                                            </Box>
                                        </PaginationMain>
                                    )}
                                </Box>
                            </>
                        )}

                        <ModalFooter className={classes.timeSectionFooter}>
                            <Tooltip title={getTooltipTitle()}>
                                <div>
                                    <Button
                                        loading={isLoading}
                                        onClick={() => handleAddAssignments()}
                                        backgroundColor={
                                            theme?.palette?.primary?.main
                                        }
                                        disabled={hasTooltipValue}
                                        disabledStyles={{
                                            backgroundColor:
                                                '#aab0b4 !important',
                                            borderColor: '#aab0b4 !important',
                                            color: `${theme?.palette.common.white} !important`,
                                            display: 'inline-block',
                                        }}
                                    >
                                        {submitButtonLabelKey
                                            ? t(submitButtonLabelKey, {
                                                  count: peopleAddedList.length,
                                              })
                                            : t(labelKey)}
                                    </Button>
                                </div>
                            </Tooltip>
                            <Button onClick={() => handleModalClose()}>
                                {t('Cancel')}
                            </Button>
                        </ModalFooter>
                    </Box>
                </AssignmentsModal>
            </CustomDialog>
        </>
    )
}

export default AddNewAssignment
