import { ShoppingCartIcon } from 'packages/eid-icons'
import { Divider, Header } from 'packages/eid-ui'
import { styled, Box, Badge, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useAllCarts, useCart } from 'hooks'
import { Skeleton } from '@mui/material'
import { useIsSmallScreenFalse, useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import { Cart } from 'components'
import CartDropdown from '../CartDropdown'
import { useEffect, useRef } from 'react'
import { useAppState } from 'appContext'
import { useTranslation } from 'react-i18next'

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: theme.palette.quaternary.main,
        color: '#ffffff',
        height: '16px',
        minWidth: '12px',
        fontSize: '10px',
    },
}))

const useStyles = makeStyles((theme) => ({
    multipleCartIndicator: {
        position: 'absolute',
        top: '-4px',
        right: 0,
        height: '8px',
        width: '8px',
        borderRadius: '50%',
        backgroundColor: theme.palette.quaternary.main,
        zIndex: 3,
    },
    dropdownAnchor: ({ anchorPosition }) => ({
        position: 'fixed',
        top: '60px',
        right: '0px',
        ...anchorPosition,
    }),
}))

const GroupedButtonContainer = styled(Box)(({ theme, showDropdown }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme?.palette?.primary?.main,
    height: '34px',

    border: '1px solid transparent',
    borderRadius: '5px',

    '& >div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '&:hover': {
            cursor: 'pointer',
        },
    },

    '& >div:first-child': {
        borderRight: showDropdown ? '1px solid  #b4b4b4' : 'none',
    },
}))

const ShoppingCartMenu = ({
    showDivider = true,
    showDrawer = true,
    protectedSubcomponent = '',
}) => {
    const query = useQuery()
    const { t } = useTranslation()
    const classes = useStyles()
    const cartOpen = query.get('cart') === 'visible'
    const { data: cart } = useCart()
    const history = useHistory()
    const isSmallScreen = useIsSmallScreenFalse()
    const theme = useTheme()
    const { hasMultipleCarts, data } = useAllCarts()
    const [{ targetResource }, dispatch] = useAppState()
    const anchorRef = useRef(null)

    // NOTE: Logic to set default target resource when loading cart
    useEffect(() => {
        if (data?.length && !targetResource?.targetAssigneeId) {
            const defultTargetResource = data[0]
            dispatch({
                type: 'SET_TARGET_RESOURCE',
                payload: {
                    targetAssigneeId: defultTargetResource.targetAssigneeId,
                    targetAssigneeFriendlyName:
                        defultTargetResource.targetAssigneeFriendlyName,
                    targetAssignee_ResourceTypeFriendlyName: t(
                        defultTargetResource.targetAssignee_ResourceTypeFriendlyName,
                    ),
                },
            })
        }
    }, [data])

    return (
        <>
            {showDivider && (
                <Header.Item
                    style={{ minWidth: isSmallScreen ? '30px' : '40px' }}
                >
                    <Divider
                        orientation="vertical"
                        variant="fullWidth"
                        style={{
                            backgroundColor: '#e8e8e8',
                            height: 26,
                        }}
                    />
                </Header.Item>
            )}

            <Header.Item
                style={{ marginRight: isSmallScreen ? '8px' : '35px' }}
            >
                {!cart ? (
                    <Skeleton width="80px" height="34px" />
                ) : (
                    <GroupedButtonContainer showDropdown={hasMultipleCarts}>
                        <Box
                            data-protectedsubcomponent={protectedSubcomponent}
                            width="45px"
                            onClick={() => {
                                query.set('cart', 'visible')
                                history.push(
                                    `${
                                        history.location.pathname
                                    }?${query.toString()}`,
                                )
                            }}
                        >
                            <StyledBadge badgeContent={cart.cartItems.length}>
                                <ShoppingCartIcon
                                    color={theme?.palette?.primary?.main}
                                    width="27px"
                                    height="27px"
                                />
                                <span
                                    ref={anchorRef}
                                    className={classes.dropdownAnchor}
                                />
                            </StyledBadge>
                        </Box>

                        {hasMultipleCarts && (
                            <>
                                <CartDropdown />
                                <Box
                                    className={classes.multipleCartIndicator}
                                />
                            </>
                        )}
                    </GroupedButtonContainer>
                )}
            </Header.Item>
            {showDrawer && (
                <Cart
                    open={cartOpen}
                    ref={anchorRef}
                    toggleDrawer={() => {
                        query.delete('cart')
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                />
            )}
        </>
    )
}
export default ShoppingCartMenu
