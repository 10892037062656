import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, styled } from '@mui/material'
import {
    useDeleteManagementRoleMember,
    useManagementRoleDirectMembers,
} from 'hooks'
import { Icon } from 'packages/eid-icons'
import { Avatar, ToggleSwitch, Tooltip, WarningModal } from 'packages/eid-ui'
import { useIsSmallScreenFalse } from 'packages/core'
import { Button, Filters, GridContainer, ListingGrid } from 'components'
import { RESOURCE_TYPE_NAMES, getFullImageUrl, imagesDisabled } from 'utils'
import DateTimeGenericComponent from './../../../../components/DynamicComponents/DateTimeGenericComponent'
import PeopleDetails from 'components/DynamicComponents/PeopleDetails'
import { PageContextProvider } from 'pageContext'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import AddNewAssignment from 'components/Popups/AddNewAssignment/AddNewAssignment'
import { useAppState } from 'appContext'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'

const canChangeBetweenDirectAndResultantMembersControlName =
    'ResourceAdmin-ManagementRoleDetails-Members-ChangeMembersType-Control'
const canAddPeopleAsmember =
    'ResourceAdmin-ManagementRoleDetails-Overview-PeopleAsMember-CanAddPeopleAsMember-Control'
const canDeletePeopleAsMember =
    'ResourceAdmin-ManagementRoleDetails-Overview-PeopleAsMember-DeletePeople-Control'
const canRemoveMultipleControlName =
    'ResourceAdmin-ManagementRoleDetails-Overview-PeopleAsMember-DeleteMultiple-Control'

const DeleteButton = styled(Box)({
    paddingRight: '1rem',
    '& button': {
        padding: '0.8rem 1.2rem',
        borderRadius: '0.4rem',
        border: 'solid 0.1rem #ff4d5f',
        backgroundColor: '#fff',
    },
})

export const SwitchContainer = styled(({ value, ...rest }) => (
    <Box {...rest} />
))(({ value }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0.4rem',
    '& >p': {
        fontSize: '1.4rem',
    },
    '& .Label': {
        color: value ? '#8b909a' : '#000000',
    },
    '& .SubLabel': {
        color: value ? '#000000' : '#8b909a',
    },
    '& .MuiFormControlLabel-root': {
        marginLeft: '1rem',
    },
}))

const useStyles = makeStyles((theme) =>
    createStyles({
        attributeParent: {
            '& p': {
                overflow: 'inherit',
            },
        },
        avatarWithFriendyName: {
            gap: '2rem',
            width: '100%',
            '& p': {
                maxWidth: '55%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        actionButtons: {
            display: 'flex',
            justifyContent: 'space-between',
            '& div:first-child ': {
                width: '80%',
                '& button ': {
                    width: '100%',
                },
            },
        },
        avatarFriendName: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
    }),
)

export const PeopleAsMembers = ({ managementRole }) => {
    const isSmallScreen = useIsSmallScreenFalse()
    const classes = useStyles()
    const [{ itemsView }] = useAppState()
    const { t } = useTranslation()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState(null)
    const [selected, setSelected] = useState([])
    const [isDirect, setIsDirect] = useState(true)

    const [deleteMember, { isLoading: isDeletingMember }] =
        useDeleteManagementRoleMember(managementRole?.id)

    const {
        list,
        loading: isLoading,
        pagination,
        loadingMore,
        refetch,
    } = useManagementRoleDirectMembers(managementRole?.id, isDirect)

    const handleDelete = () => {
        let personMgmtRolesToRemove = []
        selectedItemsToDelete.forEach((personId) =>
            personMgmtRolesToRemove.push({
                personID: personId,
                managementRoleID: managementRole?.id,
            }),
        )
        deleteMember(personMgmtRolesToRemove).then(() => {
            setSelectedItemsToDelete(null)
            setSelected([])
            refetch()
        })
    }

    const attributes = [
        {
            name: 'image',
            hideValue: true,
            showInCard: false,
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <Avatar
                        size="medium"
                        src={getFullImageUrl(item.imageThumbUrl)}
                        style={{
                            boxShadow: 'rgb(110 110 111) 0 0 0.8rem 0',
                        }}
                    />
                ),
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Overview-PeopleAsMember-List-Image',
            },
        },
        {
            hideValue: true,
            showInCard: false,
            showInTable: false,
            image: true,
            component: {
                componentName: 'ResourceImage',
                name: 'imageThumbUrl',
                defaultUrl: '/webcdn/Images/AppLogos/Genric-1.png',
            },
        },
        {
            label: t('DisplayName'),
            name: 'friendlyName',
            isHeader: true,
            showInCard: false,
            sortable: true,
            style: {
                width: '20rem',
                fontWeight: 'bold',
            },
            hideValue: true,
            className: itemsView === 'card' && classes.attributeParent,
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <Box
                        display="flex"
                        alignItems="center"
                        className={
                            itemsView === 'card' &&
                            classes.avatarWithFriendyName
                        }
                    >
                        {itemsView === 'card' && (
                            <Avatar
                                size="medium"
                                src={getFullImageUrl(item.imageThumbUrl)}
                                style={{
                                    boxShadow: 'rgb(110 110 111) 0 0 0.8rem 0',
                                }}
                            />
                        )}
                        <Box className={classes.avatarFriendName}>
                            <Tooltip title={item?.friendlyName}>
                                <Typography>{item?.friendlyName}</Typography>
                            </Tooltip>
                            <PeopleDetails data={item} />
                        </Box>
                    </Box>
                ),
            },
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ManagementRoleDetails-Overview-PeopleAsMember-List-DisplayName',
            // },
        },
        {
            label: t('Login'),
            name: 'login',
            sortable: true,
            style: {
                width: '16rem',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Overview-PeopleAsMember-List-Login',
            },
        },
        {
            name: 'company',
            label: t('Company'),
            sortable: true,
            showInCard: false,
            style: {
                width: '10rem',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Overview-PeopleAsMember-List-Company',
            },
        },
        {
            name: 'email',
            label: t('Email'),
            sortable: true,
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Overview-PeopleAsMember-List-Email',
            },
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <Box>
                        <Tooltip title={item?.email}>
                            <Typography
                                style={{
                                    fontSize: '1.3rem',
                                    maxWidth: '100%',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {item?.email || '-'}
                            </Typography>
                        </Tooltip>
                    </Box>
                ),
            },
        },
        {
            name: 'enabled',
            label: t('Enabled'),
            hideValue: true,
            showInCard: false,
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Overview-PeopleAsMember-List-Enabled',
            },
            component: {
                componentName: 'renderer',
                render: (item) => {
                    return item.enabled === null
                        ? '-'
                        : item.enabled
                        ? t('Yes')
                        : t('No')
                },
            },
        },
        {
            name: 'lastAttestationDateTimeUtc',
            label: t('LastCertified'),
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Overview-PeopleAsMember-List-LastCertified',
            },
            hideValue: true,
            showInCard: false,
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <DateTimeGenericComponent
                        data={item}
                        attribute={{ name: 'lastAttestationDateTimeUtc' }}
                    />
                ),
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box
                            display={'flex'}
                            className={
                                itemsView === 'card' && classes.actionButtons
                            }
                        >
                            {hasAccessToControl(canDeletePeopleAsMember) && (
                                <DeleteButton>
                                    <Button.Delete
                                        showEndIcon={false}
                                        onClick={() => {
                                            setSelectedItemsToDelete([data?.id])
                                        }}
                                    />
                                </DeleteButton>
                            )}
                            {itemsView === 'card' && (
                                <UIActionsGearButton item={data} noun='ResourceAdminPeopleMembers' />
                            )}
                        </Box>
                    )
                },
            },
        },
        {
            hideValue: true,
            showInCard: false,
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Overview-PeopleAsMember-List-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (data) => <UIActionsGearButton item={data} noun='ResourceAdminPeopleMembers' />,
            },
        },
    ]

    const handleDeleteBulk = () => {
        setSelectedItemsToDelete(selected)
    }
    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x !== item.id
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item.id])
        }
    }

    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            const filteredList = list.map((item) => item.id)
            setSelected(filteredList)
        }
    }

    const bindSelectedItemName = () => {
        try {
            if (selectedItemsToDelete?.length === 1) {
                const deleteTitle =
                    list.find((x) => x.id === selectedItemsToDelete[0])
                        ?.friendlyName || ''
                if (deleteTitle) {
                    return `${t('Delete')} ${deleteTitle}`
                }
            }
        } catch (err) {}

        return t('DeleteMembers')
    }

    const selectAllAction =
        isDirect && hasAccessToControl(canRemoveMultipleControlName)
            ? {
                  render: () => {
                      return (
                          <Box>
                              <BulkRemoveButton
                                  selectedCount={selected.length}
                                  label={t('RemoveSelectedMembers', {
                                      count: selected.length,
                                  })}
                                  onClick={handleDeleteBulk}
                                  dataProtectedsubcomponent={
                                      canRemoveMultipleControlName
                                  }
                              />
                          </Box>
                      )
                  },
              }
            : null

    return (
        <>
            <ListingGrid>
                <Box
                    display="flex"
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                    justifyContent="space-between"
                >
                    {hasAccessToControl(canAddPeopleAsmember) ? (
                        <Box data-protectedsubcomponent={canAddPeopleAsmember}>
                            <PageContextProvider key="AddNewMembersManagementRoles">
                                <AddNewAssignment
                                    resourceType={
                                        RESOURCE_TYPE_NAMES.MANAGEMENT_ROLES
                                    }
                                    labelKey={'AddNewPeopleAsMembers'}
                                    searchUrl={`api/people/unAssignedPeopleByManagementRole/${managementRole?.id}`}
                                    targetAssigneeId={managementRole.id}
                                    startIcon={
                                        <Icon
                                            name={'Plus'}
                                            fill="white"
                                            style={{
                                                width: '1.6rem',
                                                height: '1.6rem',
                                                marginRight: '0.5rem',
                                            }}
                                        />
                                    }
                                />
                            </PageContextProvider>
                        </Box>
                    ) : (
                        <Box></Box>
                    )}

                    <Box
                        display="flex"
                        alignItems="center"
                        style={{
                            gap: '2.4rem',
                        }}
                    >
                        {hasAccessToControl(
                            canChangeBetweenDirectAndResultantMembersControlName,
                        ) && (
                            <SwitchContainer
                                data-protectedsubcomponent={
                                    canChangeBetweenDirectAndResultantMembersControlName
                                }
                                value={!isDirect}
                            >
                                <Typography className="Label">
                                    {t('Direct')}
                                </Typography>
                                <ToggleSwitch
                                    value={!isDirect}
                                    onChange={() => {
                                        setIsDirect((p) => !p)
                                    }}
                                />

                                <Typography className="SubLabel">
                                    {t('Resultant')}
                                </Typography>
                            </SwitchContainer>
                        )}
                        <Box width="30.8rem" display="flex" alignItems="center">
                            <Filters.TextSearch outlined />
                        </Box>
                    </Box>
                </Box>
                <GridContainer
                    style={{
                        padding: '1.6rem 0',
                        marginTop: '0',
                        backgroundColor: isSmallScreen
                            ? 'white'
                            : 'transparent',
                    }}
                >
                    <EidTableGrid
                        selectAllAction={selectAllAction}
                        rowSelection={isDirect}
                        noDataMessage={t('NoDataMessage')}
                        pagination={pagination}
                        isLoading={isLoading}
                        isLoadingMore={loadingMore}
                        attributes={getAccessBasedAttributes(attributes).filter(
                            (x) => !x.image || !imagesDisabled,
                        )}
                        list={list}
                        handleSelectAllClick={handleSelectAllClick}
                        selected={selected}
                        handleClick={handleRowSelection}
                    />
                </GridContainer>
            </ListingGrid>

            {selectedItemsToDelete && (
                <WarningModal
                    title={bindSelectedItemName()}
                    description={t('MembersDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemsToDelete.length}
                    cancel={() => setSelectedItemsToDelete(null)}
                    proceeding={isDeletingMember}
                    proceed={handleDelete}
                />
            )}
        </>
    )
}
