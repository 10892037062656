import { Box, styled } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useAuthFieldTypeValues } from 'hooks'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'
import Filters from 'components/Filters'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '.3rem',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '4rem',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const FieldTypeValues = ({ fieldType }) => {
    const { t } = useTranslation()

    const { list, loading, loadingMore, pagination } = useAuthFieldTypeValues(
        fieldType?.id,
    )
    const { getAccessBasedAttributes } = useSubcomponents()
    const attributes = [
        {
            name: 'name',
            sortable: true,
            label: 'FieldTypeName',
        },
        {
            name: 'valueFriendlyName',
            sortable: true,
            label: 'ValueDisplayName',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppOwnedFieldTypes-FieldTypeValues-ValueDisplayName',
            },
        },
        {
            name: 'valueName',
            sortable: true,
            label: 'Value',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppOwnedFieldTypes-FieldTypeValues-Value',
            },
        },
        {
            name: 'selectionRuleName',
            sortable: true,
            label: 'UISelectionControl',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppOwnedFieldTypes-FieldTypes-UISelectionControl',
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="end"
                padding="1.6rem 3.2rem"
            >
                <Box width="24rem" display="flex" alignItems="center">
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default FieldTypeValues
