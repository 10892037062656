import { Box, Checkbox, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'
import classNames from 'classnames'
import { Icon } from 'packages/eid-icons'
import { Tooltip } from 'packages/eid-ui'
import { PageContextProvider } from 'pageContext'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditAssignmentsWithFieldTypes from 'components/Popups/EditAssignmentsWithFieldTypes'
import DialogHeader from 'components/Popups/EditAssignmentsWithFieldTypes/DialogHeader'

const useStyles = makeStyles({
    Applicationroot: {
        width: '100%',

        '& p': {
            fontSize: '12px',
            color: 'rgb(180, 180, 180)',
            textTransform: 'uppercase',
            marginBottom: '10px',
        },
    },
    Applicationinner: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    Checkboxcontainer: {
        borderBottom: '1px solid #efeeee',
        width: '31%',
        padding: '6px 15px',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        '&:hover': {
            background: '#fafcff',
            borderLeft: '3px solid #01539c',
            boxSizing: 'border-box',
        },
        '& p': {
            color: '#01539c',
            fontSize: '12px',
            fontWeight: '600',
            display: 'inline-block',
            margin: '0 5px',
        },
    },
    Checkboxcontaineractive: {
        background: '#fafcff',
        borderLeft: '3px solid #01539c',
        boxSizing: 'border-box',
    },
    nonCheckedIcon: {
        '& svg': {
            opacity: '0.2',
        },
    },
})

const PeopleApplicationList = ({
    personDetails,
    handleApplicationChange,
    bindAssignedPeopleList,
    selectedApplicationList = [],
    data,
    isLoading,
}) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const classes = useStyles()
    const [selectedApplication, setSelectedApplication] = useState(null)

    const [activeTab, setActiveTab] = useState('roles')

    const getCheckboxStyles = (element) => {
        if (!element) {
            return {
                color: theme?.palette?.primary?.main,
                borderColor: theme?.palette?.primary?.main,
                isChecked: false,
            }
        }

        const assignedItem =
            element?.newlyAddedItems?.map((item) => item.id) || []
        const unAssignedItem =
            element?.newlyRemovedItems?.map((item) => item.id) || []

        const uniqueAssignedIds = assignedItem.filter((id, index) => {
            return assignedItem.indexOf(id) === index
        })
        const uniqueUnAssignedIds = unAssignedItem.filter((id, index) => {
            return unAssignedItem.indexOf(id) === index
        })

        const filteredAssignedOnly = uniqueAssignedIds.filter(
            (item) => !uniqueUnAssignedIds.includes(item),
        )
        const filteredUnAssignedOnly = uniqueUnAssignedIds.filter(
            (item) => !uniqueAssignedIds.includes(item),
        )

        if (
            filteredAssignedOnly.length < 1 &&
            filteredUnAssignedOnly.length > 0
        ) {
            return {
                color: 'transparent',
                border: '1px solid orange',
                isChecked: false,
            }
        }

        if (filteredAssignedOnly.length > 0) {
            return {
                color: 'orange',
                border: '',
                isChecked: true,
            }
        }
        return {
            color: theme?.palette?.primary?.main,
            border: '',
            isChecked: false,
        }
    }

    // const onSubmit = (newlyAddedItems, newlyRemovedItems, item) => {
    //     const dataObject = {
    //         applicationId: item.id,
    //         newlyAddedItems: newlyAddedItems,
    //         newlyRemovedItems: newlyRemovedItems,
    //     }
    //     handleApplicationChange(dataObject)
    // }
    //NOTE : Above onSubmit is no longer in use. Keeping it in case revert is needed

    const allTabsForAssignment = [
        {
            labelKey: 'Common_ApplicationRoles',
            value: 'roles',
        },
        {
            labelKey: 'AppRights',
            value: 'rights',
        },
    ]

    return (
        <Box className={classes.Applicationroot}>
            {isLoading ? (
                <Fragment>
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                </Fragment>
            ) : (
                <Fragment>
                    <Typography> {t('Applications')}</Typography>
                    <Box className={classes.Applicationinner}>
                        {data?.map((item, index) => {
                            const colorObj = getCheckboxStyles(
                                selectedApplicationList.find(
                                    (a) => a.applicationId === item.id,
                                ),
                            )
                            return (
                                <Box
                                    key={`application-list-${index}`}
                                    className={classNames(
                                        classes.Checkboxcontainer,
                                        {
                                            [classes.Checkboxcontaineractive]:
                                                item.isLocalRoleAssigned || item.isLocalRightAssigned,
                                        },
                                    )}
                                >
                                    <Checkbox
                                        icon={
                                            <Box
                                                className={
                                                    classes.nonCheckedIcon
                                                }
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Icon name="CheckBox" />
                                            </Box>
                                        }
                                        checkedIcon={
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Icon
                                                    name="CheckedBox"
                                                    color={colorObj.color}
                                                    style={{
                                                        border: colorObj.border,
                                                    }}
                                                />
                                            </Box>
                                        }
                                        checked={
                                            item.isLocalRoleAssigned ||
                                            item.isLocalRightAssigned ||
                                            colorObj.isChecked
                                        }
                                    />
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            setSelectedApplication(item)
                                        }
                                    >
                                        <Tooltip title={item?.friendlyName}>
                                            <Typography
                                                style={{
                                                    maxWidth: '200px',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {item?.friendlyName}
                                            </Typography>
                                        </Tooltip>
                                        <Icon name="Launch" />
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Fragment>
            )}

            {selectedApplication && (
                <PageContextProvider
                    key={'Applications' + selectedApplication?.id}
                >
                    <DialogHeader
                        headerText={{
                            dynamicText: selectedApplication?.friendlyName,
                            text: t('AccessAssignment'),
                        }}
                        setShowAssignmentModal={() =>
                            setSelectedApplication(null)
                        }
                        showAssignmentModal={selectedApplication}
                        allTabs={allTabsForAssignment}
                        activeTab={activeTab}
                        handleTabChange={(value) => setActiveTab(value)}
                    >
                        {activeTab === 'roles' ? (
                            <EditAssignmentsWithFieldTypes
                                showAssignmentModal={
                                    selectedApplication ? true : false
                                }
                                handleClose={() => setSelectedApplication(null)}
                                assignedUrl={`/api/AzureRoles/assignmentsByApplication/${selectedApplication?.resourceSystemModuleID}`}
                                unAssignedUrl={`/api/AzureRoles/GetEligibleAppRolesByApplicationByAssignee`}
                                selectedApplicationId={selectedApplication?.id}
                                unAssignedQueryParams={{
                                    applicationId: selectedApplication?.id,
                                    eligibilityAssigneeId: personDetails.id,
                                }}
                                assignedQueryParams={{
                                    assigneeId: personDetails?.id,
                                }}
                                searchPlaceHolder={'SearchApplicationRoles'}
                                showAccessRequestPolicy={true}
                                fetchRequestPolicyItemWise={true}
                                requestPolicyIdProperty="requestPolicyId"
                                showStartDate={false}
                                fieldTypeProps={{
                                    assignee: personDetails,
                                    isRole: true,
                                    keyForRoleRightId: 'localRoleId',
                                }}
                                requireRemappingInAssignedList={true}
                                newMappingForAssignedList={{
                                    friendlyName: 'role',
                                    targetAssigneeIdAssigned: 'localRoleId',
                                }}
                                addSelectedOnly={true}
                                assignedPeopleList={bindAssignedPeopleList(
                                    selectedApplication,
                                    true,
                                )}
                                unAssignedPeopleList={bindAssignedPeopleList(
                                    selectedApplication,
                                    false,
                                )}
                                itemType={'role'}
                            />
                        ) : (
                            <EditAssignmentsWithFieldTypes
                                showAssignmentModal={
                                    selectedApplication ? true : false
                                }
                                handleClose={() => setSelectedApplication(null)}
                                assignedUrl={`api/LocalRights/appRoles/membersByApplicationID`}
                                unAssignedUrl={`api/LocalRights/GetEligibleAppRightsByApplicationByAssignee`}
                                selectedApplicationId={selectedApplication?.id}
                                searchPlaceHolder="SearchRights"
                                assignedQueryParams={{
                                    resourceSystemModuleID:
                                        selectedApplication?.resourceSystemModuleID,
                                    assigneeId: personDetails?.id,
                                }}
                                unAssignedQueryParams={{
                                    applicationId: selectedApplication?.id,
                                    eligibilityAssigneeId: personDetails.id,
                                }}
                                showAccessRequestPolicy={true}
                                fetchRequestPolicyItemWise={true}
                                showStartDate={false}
                                fieldTypeProps={{
                                    assignee: personDetails,
                                    isRole: false,
                                    keyForRoleRightId: 'localRightId',
                                }}
                                requireRemappingInAssignedList={true}
                                newMappingForAssignedList={{
                                    friendlyName: 'appRightFriendlyName',
                                    name: 'appRightName',
                                    targetAssigneeIdAssigned: 'localRightId',
                                }}
                                itemType={'right'}
                            />
                        )}
                    </DialogHeader>
                </PageContextProvider>
            )}
        </Box>
    )
}

export default PeopleApplicationList
