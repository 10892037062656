import { Fragment } from 'react'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Paper } from '@mui/material'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { useAccessRequestPolicy, useOwnersApproversDeputies } from 'hooks'
import { useTranslation } from 'react-i18next'
import { PeopleListCard } from 'components/PeopleListCard/PeopleListCard'
import { smallScreenWidth } from 'utils'
import PersonDetailsLazy from 'components/PersonDetailsLazy'
import useSubcomponents from 'useSubcomponents'
import { PolicyDetails } from 'components'

const canSeeOwnersControlName =
    'ResourceAdmin-ApplicationDetails-RoleDefinitions-Owners-Control'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            width: 'auto',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: theme?.palette?.primary?.main,
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },
        attribute: {
            marginTop: '-8px',
        },
        attributeLabel: {
            minWidth: '265px',
        },
        attributeValue: {
            wordBreak: 'unset',
        },
    }),
)

export const Overview = (props) => {
    const { role } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const policy = useAccessRequestPolicy(role.requestPolicyId)
    const { data: ownersAndApprovers, isLoading: loading } =
        useOwnersApproversDeputies(
            hasAccessToControl(canSeeOwnersControlName) ? role.id : null,
        )

    const attributes = [
        {
            label: 'Role',
            value: 'friendlyName',
        },
        {
            label: 'RoleType',
            value: 'roleTypeFriendlyName',
        },
        {
            label: 'SystemType',
            value: 'resourceSystemTypeFriendlyName',
        },
        {
            label: 'Tenant',
            value: 'resourceSystemName',
        },
        {
            label: 'Description',
            value: 'description',
        },
        {
            label: 'PublishInIAMShop',
            value: 'requestable',
            render: (data) => (data && data.requestable ? t('Yes') : t('No')),
        },
        {
            label: 'FulfillmentGroup',
            value: 'fulfillmentGroupFriendlyName',
        },
    ]

    const roleDetail = (
        <Fragment>
            <Box className={classes.basicInfo}>
                {attributes &&
                    attributes.map((att, i) => {
                        return (
                            <Attribute
                                className={classes.attribute}
                                orientation="horizontal"
                                key={att.value + i}
                            >
                                <AttributeLabel
                                    className={classes.attributeLabel}
                                >
                                    {t(att.label)}
                                </AttributeLabel>
                                <AttributeValue
                                    className={classes.attributeValue}
                                >
                                    {att.render
                                        ? att.render(role)
                                        : role && role[att.value]}
                                </AttributeValue>
                            </Attribute>
                        )
                    })}
            </Box>
        </Fragment>
    )

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>
                <Box className={classes.section}>
                    <Box>
                        {roleDetail}
                        <PolicyDetails policy={policy} />
                    </Box>
                </Box>

                {hasAccessToControl(canSeeOwnersControlName) && (
                    <Box
                        data-protectedsubcomponent={canSeeOwnersControlName}
                        className={classes.section}
                    >
                        <Attribute>
                            <AttributeLabel>
                                {t('RoleDefinitions_Owners')}
                            </AttributeLabel>
                            <PeopleListCard
                                data={ownersAndApprovers?.owners}
                                isLoading={loading}
                                pageSize={3}
                            />
                        </Attribute>
                    </Box>
                )}
                <Box className={classes.section}>
                    <Attribute>
                        <AttributeLabel>{t('ResponsibleParty')}</AttributeLabel>
                        {role?.ownerIsPerson ? (
                            <PersonDetailsLazy
                                personId={role.responsiblePartyId}
                                friendlyName={role.responsiblePartyFriendlyName}
                                imageThumbUrl={
                                    role.responsiblePartyImageThumbUrl
                                }
                            />
                        ) : (
                            <AttributeValue>
                                {role?.responsiblePartyFriendlyName || '-'}
                            </AttributeValue>
                        )}
                    </Attribute>
                </Box>
            </Box>
        </Fragment>
    )
    return (
        <>
            <Paper
                style={{
                    padding: '32px',
                    width: 'fit-content',
                    minWidth: '100%',
                }}
            >
                <Box className={classes.section}>{renderContent()}</Box>
            </Paper>
        </>
    )
}
