import { FC, Fragment, useEffect, useState } from 'react'
import {
    useAddItemsToCart,
    useAddItemToCart,
    useApplication,
    useCart,
    useRemoveItemsFromCart,
} from 'hooks'
import { Skeleton } from '@mui/material'
import AppRoleRight from './AppRoleRight'
import { Box, Typography, useTheme, styled } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Button } from 'components'
import { useHistory } from 'react-router'
import { Icon } from 'packages/eid-icons'
import { Loader, Tooltip, useNotification } from 'packages/eid-ui'
import cartHelpers from 'containers/Cart/cartHelpers'
import {
    checkAllRequiredFieldTypeFilled,
    checkFieldTypeWithValidSourceValues,
    findChangedFieldTypesForAppRight,
    findChangedFieldTypesForAppRole,
    getModifiedFieldTypeValue,
    mergeCartAndApiJson,
    updateJsonMappingWithApiFormat,
    useQuery,
} from 'packages/core'
import { usePageContext } from 'pageContext'
import { v4 as uuidv4 } from 'uuid'
import NoDataFoundMessage from 'packages/eid-ui/NoDataFoundMessage'
export type DynamicFieldTypesSelectorProps = {
    appRoleRight: any
    isLoading: boolean
    assigneePerson: any
    isAppRole: boolean
    keyForRoleRightId: string
    onSave: any
    applicationId: string
    showInfoMessage?: boolean
}
const updateJsonToAddParentIdToFieldTypes = (data: any, defaultGuuid: any) => {
    try {
        if (data?.Rights?.length) {
            const newData = data?.Rights?.map((right: any) => {
                let fieldType = []
                if (right?.FieldType?.length) {
                    fieldType = right.FieldType.map((i: any) => ({
                        ...i,
                        parentRightId: right.AzLocalRightID,
                    }))
                }
                return { ...right, FieldType: fieldType }
            })
            return { ...data, Rights: newData }
        } else {
            if (data?.FieldType?.length) {
                const fieldTypes = data.FieldType.map((i: any) => ({
                    ...i,
                    parentRightId: data.AzLocalRightID,
                }))
                if (data?.AzAssigneeLocalRightScopeID || !defaultGuuid) {
                    return { ...data, FieldType: fieldTypes }
                } else {
                    return {
                        ...data,
                        AzAssigneeLocalRightScopeID: defaultGuuid,
                        FieldType: fieldTypes,
                    }
                }
            }
            return data
        }
    } catch (err) {}
    return data
}

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '1.25rem 1rem',
        background: '#fbfbfd',
    },
    header: {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
        padding: '1.7rem 3.2rem 1.2rem',
        borderTop: 'solid 1px #e8e8e8',
        borderBottom: 'solid 1px #e8e8e8',
        background: `linear-gradient(90deg, rgba(246,246,248,1) 0%, rgba(244,244,246,1) 0%, rgba(238,103,35,1) 0%, rgba(23,154,170,0.95) 0%, rgba(23,129,188,0.95) 100%),repeating-linear-gradient(${theme?.palette?.common?.white}, ${theme?.palette?.common?.white} 3px, rgba(0, 0, 0, 0.02) 3px, rgba(0, 0, 0, 1.02) 4px)`,
        '& h2': {
            fontSize: '1.6rem',
            fontWeight: '600',
            color: '#fff',
        },
    },

    saveButton: {
        cursor: 'pointer',
        position: 'relative',
        height: '40px',
        padding: '8px 12px',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'capitalize',
        fontSize: '15px',
        backgroundColor: theme?.palette?.primary?.main,
        border: `solid 1px ${theme?.palette?.primary?.main}`,

        color: `${theme?.palette?.common?.white} !important`,
        '&:hover': {
            backgroundColor: theme?.palette?.primary?.main,
            color: theme?.palette?.common?.white,
            cursor: 'hand',
        },
    },
    saveBtnContainer: {
        width: 'fit-content',
        '& .Mui-disabled': {
            backgroundColor: `${theme?.palette?.disabled?.main} !important`,
            color: `${theme?.palette?.common?.white} !important`,
            borderColor: `${theme?.palette?.disabled?.main} !important`,
        },
    },
}))

const GradientSpacer = styled(Box)({
    background:
        'linear-gradient(180deg, #e9e9eb 0%, #f7f7f9 50%, #f1f1f3 100%)',
    height: '1.5rem',
})

const DynamicFieldTypesSelector: FC<DynamicFieldTypesSelectorProps> = ({
    appRoleRight,
    assigneePerson,
    isLoading,
    isAppRole,
    keyForRoleRightId,
    onSave,
    applicationId,
    showInfoMessage = false
}) => {
    const [isFormValidated, setIsFormValidated] = useState(false)
    const classes = useStyles()
    const { t } = useTranslation()
    const [{ appRightsFieldTypes }, dispatch]: any = usePageContext()
    const history = useHistory()
    const theme = useTheme()
    const [addItemToCart, { isLoading: addToCartLoading }] = useAddItemToCart()
    const [addItemsToCart, { isLoading: addItemsToCartLoading }] =
        useAddItemsToCart()
    const query = useQuery()
    const [apiResponseJson, setApiResponseJson] = useState<any>(null)
    const { data: cart } = useCart()
    const selectedApplicationId = applicationId || query.get('applicationId')
    const { data: application } = useApplication(selectedApplicationId)
    const { showWarningMessage } = useNotification()
    const [removeItemsFromCart, { isLoading: isRemoving }] =
        useRemoveItemsFromCart()

    useEffect(() => {
        if (appRoleRight) {
            mapCartAndApiJson()
        } else if (appRightsFieldTypes) {
            setApiResponseJson(null)
            dispatch({
                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                payload: null,
            })
        }
        return () => resetFieldTypes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appRoleRight])

    const resetFieldTypes = () => {
        setApiResponseJson(null)
        dispatch({
            type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
            payload: null,
        })
    }

    const mapCartAndApiJson = () => {
        try {
            const apiDataResponse = JSON.parse(appRoleRight)
            const dataObject = Array.isArray(apiDataResponse)
                ? apiDataResponse[0]
                : apiDataResponse
            const parsedObject = updateJsonMappingWithApiFormat(
                dataObject,
                false,
            )
            const cartItem = getCartItemFromCart()
            const itemCart = cartItem?.length ? cartItem[0] : null

            if (
                itemCart &&
                !parsedObject?.SplitBusinessRequestApprovalPerFieldTypeValue
            ) {
                const fieldType = itemCart?.fieldTypeValueJson
                if (fieldType) {
                    const cartJson = JSON.parse(fieldType)
                    const cartDataObj = Array.isArray(cartJson)
                        ? cartJson[0]
                        : cartJson

                    const parsedCartObj = updateJsonMappingWithApiFormat(
                        cartDataObj,
                        false,
                    )
                    const combinedJson = mergeCartAndApiJson(
                        parsedCartObj,
                        parsedObject,
                    )
                    extractDataAndBindValues(combinedJson)
                } else {
                    extractDataAndBindValues(parsedObject)
                }
            } else {
                extractDataAndBindValues(parsedObject)
            }
        } catch (err) {}
    }

    const extractDataAndBindValues = (parsedObject: any) => {
        try {
            const defaultGuuid =
                parsedObject?.SplitBusinessRequestApprovalPerFieldTypeValue
                    ? uuidv4()
                    : undefined
            dispatch({
                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                payload: updateJsonToAddParentIdToFieldTypes(
                    parsedObject,
                    defaultGuuid,
                ),
            })
            setApiResponseJson(
                updateJsonToAddParentIdToFieldTypes(parsedObject, defaultGuuid),
            )
        } catch (err) {}
    }

    const bindUpdatedFieldTypeValues = () => {
        const dataObject = JSON.parse(appRoleRight)
        const parsedData = Array.isArray(dataObject)
            ? dataObject[0]
            : dataObject
        const parsedMappedWithApiFormat = updateJsonMappingWithApiFormat(
            parsedData,
            false,
        )

        if (isAppRole) {
            return updateJsonMappingWithApiFormat(
                findChangedFieldTypesForAppRole(
                    parsedMappedWithApiFormat,
                    appRightsFieldTypes,
                    ['SourceValues'],
                ),
                true,
            )
        } else {
            return updateJsonMappingWithApiFormat(
                findChangedFieldTypesForAppRight(
                    parsedMappedWithApiFormat,
                    appRightsFieldTypes,
                    ['SourceValues'],
                ),
                true,
            )
        }
    }

    const preAdd = () => {
        const itemToAdd = cartHelpers.applicationsFieldTypeJsonCartItem({
            targetResourceId: assigneePerson?.assigneeId,
            assignmentType: 'Add',
            application: application,
            assigneePerson: assigneePerson,
            targetAssigneeId: assigneePerson[keyForRoleRightId],
            fieldTypeValueJson: appRightsFieldTypes
                ? JSON.stringify([bindUpdatedFieldTypeValues()])
                : undefined,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const preAddSplitted = (fieldType: any) => {
        const itemToAdd = cartHelpers.applicationsFieldTypeJsonCartItem({
            targetResourceId: assigneePerson?.assigneeId,
            assignmentType: 'Add',
            application: application,
            assigneePerson: assigneePerson,
            targetAssigneeId: assigneePerson[keyForRoleRightId],
            fieldTypeValueJson: fieldType
                ? JSON.stringify([fieldType])
                : undefined,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const handleSave = () => {
        try {
            if (checkIfValidJson()) {
                if (checkIfAllRequiredFieldFilled()) {
                    const cartItems = getCartItemFromCart()
                    if (
                        appRightsFieldTypes?.SplitBusinessRequestApprovalPerFieldTypeValue
                    ) {
                        let itemsToAdd: any = []
                        const fieldTypes = appRightsFieldTypes?.FieldType || []
                        const ordinalFieldTypeData =
                            apiResponseJson?.FieldType || []
                        fieldTypes.forEach((item: any) => {
                            const fieldType: any = getModifiedFieldTypeValue(
                                ordinalFieldTypeData,
                                item,
                            )
                            if (fieldType?.isModified) {
                                const parentObject = { ...appRightsFieldTypes }
                                if (fieldType?.AzFieldTypeID) {
                                    const valueType =
                                        fieldType?.AzFieldTypeSelectionRuleTypeID ===
                                        3
                                            ? 'AssignedValues'
                                            : 'AssignedValue'
                                    let fieldValue = fieldType[valueType]

                                    if (Array.isArray(fieldValue)) {
                                        const fieldToAdd = fieldValue.filter(
                                            (x: any) =>
                                                x.ActionType !== 'NoChange',
                                        )
                                        fieldToAdd.forEach((field: any) => {
                                            const itemToAdd: any =
                                                preAddSplitted({
                                                    ...parentObject,
                                                    FieldType: [
                                                        {
                                                            ...fieldType,
                                                            [valueType]: [
                                                                field,
                                                            ],
                                                        },
                                                    ],
                                                })
                                            itemsToAdd.push(itemToAdd)
                                        })
                                    } else {
                                        if (
                                            fieldType?.AzFieldTypeSelectionRuleTypeID !==
                                                3 &&
                                            fieldValue
                                        ) {
                                            fieldValue = [fieldValue]
                                        }
                                        const itemToAdd: any = preAddSplitted({
                                            ...parentObject,
                                            FieldType: [
                                                {
                                                    ...fieldType,
                                                    [valueType]: fieldValue,
                                                },
                                            ],
                                        })
                                        itemsToAdd.push(itemToAdd)
                                    }
                                }
                            }
                        })

                        if (!itemsToAdd?.length) return
                        if (cartItems?.length) {
                            removeAndAddItemsToCart(cartItems, itemsToAdd)
                        } else {
                            addItemsHandle(itemsToAdd)
                        }
                    } else {
                        if (cartItems?.length) {
                            const cartItemIds = cartItems.map(
                                (cartItem: any) => cartItem.id,
                            )
                            removeItemsFromCart(cartItemIds).then(() => {
                                handleAddToCart()
                            })
                        } else {
                            handleAddToCart()
                        }
                    }
                } else {
                    showWarningMessage(t('Common_RequiredFieldMissingValues'))
                    setIsFormValidated(true)
                }
            } else {
                showWarningMessage(t('Common_WronglyConfiguredJson'))
            }
        } catch (err) {}
    }

    const removeAndAddItemsToCart = (itemsToRemove: any, itemsToAdd: any) => {
        try {
            const cartItemIds = itemsToRemove.map(
                (cartItem: any) => cartItem.id,
            )
            removeItemsFromCart(cartItemIds).then(() => {
                addItemsHandle(itemsToAdd)
            })
        } catch (err) {}
    }

    const addItemsHandle = (itemsToAdd: any) => {
        if (onSave) {
            onSave(itemsToAdd, true)
        } else {
            try {
                addItemsToCart(itemsToAdd).then(() => {
                    query.delete('roleId')
                    query.delete('rightId')
                    query.delete('azGlobalRightID')
                    query.delete('showSubDrawer')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                })
            } catch (err) {}
        }
    }

    const handleAddToCart = () => {
        try {
            const itemToAdd: any = preAdd()
            if (itemToAdd === undefined) return
            if (onSave) {
                onSave([itemToAdd], false)
            } else {
                addItemToCart(itemToAdd).then(() => {
                    query.delete('roleId')
                    query.delete('rightId')
                    query.delete('azGlobalRightID')
                    query.delete('showSubDrawer')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                })
            }
        } catch (err) {}
    }

    const checkIfDataAvailableToBind = () => {
        try {
            if (!apiResponseJson) {
                return false
            }
            if (isAppRole) {
                if (apiResponseJson?.Rights?.length) {
                    return true
                }
            } else {
                if (apiResponseJson?.FieldType?.length) {
                    return true
                }
            }
        } catch (err) {}
        return false
    }

    const getCartItemFromCart = () => {
        let itemCart =
            cart?.cartItems?.filter(
                (item: any) =>
                    item.requestableResourceId === assigneePerson?.assigneeId,
            ) || null
        itemCart = itemCart.filter(
            (x: any) => x?.assignmentId === assigneePerson?.id,
        )
        return itemCart
    }

    const checkIfAlreadyAvailableInCart = () => {
        try {
            const cartItem = getCartItemFromCart()
            if (!cartItem?.length) {
                return false
            }
            const itemToDelete = cartItem.filter(
                (itemCart: any) => itemCart.assignmentType === 'Remove',
            )
            if (itemToDelete?.length) {
                return true
            }
        } catch (err) {}
        return false
    }

    const getTooltipTitle = () => {
        return checkIfAlreadyAvailableInCart()
            ? t('Common_ItemAlreadyInCartForRemoval')
            : !checkIfChanged()
            ? t('Common_NoChangeFound')
            : ''
    }

    const checkIfAllRequiredFieldFilled = () => {
        const data = { ...appRightsFieldTypes }
        let isRequiredFilled = true
        try {
            if (data?.Rights?.length) {
                data?.Rights?.forEach((right: any) => {
                    if (right?.FieldType?.length) {
                        right?.FieldType?.forEach((item: any) => {
                            if (!checkAllRequiredFieldTypeFilled(item)) {
                                isRequiredFilled = false
                            }
                        })
                    }
                })
            } else {
                if (data?.FieldType?.length) {
                    data?.FieldType?.forEach((item: any) => {
                        if (!checkAllRequiredFieldTypeFilled(item)) {
                            isRequiredFilled = false
                        }
                    })
                }
            }
        } catch (err) {}
        return isRequiredFilled
    }

    const checkIfValidJson = () => {
        const data = { ...appRightsFieldTypes }
        let isValidJson = true
        if (!isFormValidated) {
            return isValidJson
        }

        try {
            if (data?.Rights?.length) {
                data?.Rights?.forEach((right: any) => {
                    if (right?.FieldType?.length) {
                        right?.FieldType?.forEach((item: any) => {
                            if (!checkFieldTypeWithValidSourceValues(item)) {
                                isValidJson = false
                            }
                        })
                    }
                })
            } else {
                if (data?.FieldType?.length) {
                    data?.FieldType?.forEach((item: any) => {
                        if (!checkFieldTypeWithValidSourceValues(item)) {
                            isValidJson = false
                        }
                    })
                }
            }
        } catch (err) {}
        return isValidJson
    }

    const checkIfChanged = () => {
        let isModified = false
        try {
            const modifiedData: any = isAppRole
                ? findChangedFieldTypesForAppRole(
                      apiResponseJson,
                      appRightsFieldTypes,
                      ['SourceValues'],
                  )
                : findChangedFieldTypesForAppRight(
                      apiResponseJson,
                      appRightsFieldTypes,
                      ['SourceValues'],
                  )

            if (isAppRole) {
                const appRights = modifiedData?.Rights || []
                appRights.forEach((right: any) => {
                    const rightFieldTypes = right?.FieldType || []
                    const changedFieldType = rightFieldTypes.find(
                        (x: any) => x.isModified,
                    )
                    if (changedFieldType) {
                        isModified = true
                    }
                })
            } else {
                const rightFieldTypes = modifiedData?.FieldType || []
                const changedFieldType = rightFieldTypes.find(
                    (x: any) => x.isModified,
                )
                if (changedFieldType) {
                    isModified = true
                }
            }
        } catch (err) {}
        return isModified
    }

    const checkIfRequiredFieldExist = (right: any) => {
        let isRequired = false
        if (right?.FieldType?.length) {
            for (let i = 0; i < right?.FieldType?.length; i++) {
                if (right?.FieldType[i].IsRequired) {
                    isRequired = true
                    break
                }
            }
        }
        return isRequired
    }

    return (
        <Fragment>
            {checkIfDataAvailableToBind() && !isLoading ? (
                <Fragment>
                    <GradientSpacer />
                    <Box className={classes.header}>
                        <Typography variant="h2">
                            {t('Common_SelectFieldTypeValues')}
                        </Typography>
                    </Box>
                    <Box className={classes.root}>
                        <Box>
                            {isAppRole ? (
                                <Fragment>
                                    {appRightsFieldTypes?.Rights?.length
                                        ? appRightsFieldTypes.Rights.map(
                                              (item: any, index: number) => (
                                                  <AppRoleRight
                                                      rightInfo={item}
                                                      key={`app-role-right-${index}`}
                                                      isAppRole={true}
                                                      isFormValidated={
                                                          isFormValidated
                                                      }
                                                      defaultExpanded={
                                                          appRightsFieldTypes?.SplitBusinessRequestApprovalPerFieldTypeValue
                                                      }
                                                      showRequiredFieldMessage={
                                                          checkIfRequiredFieldExist(
                                                              item,
                                                          ) &&
                                                          !checkIfAllRequiredFieldFilled()
                                                      }
                                                  />
                                              ),
                                          )
                                        : null}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <AppRoleRight
                                        rightInfo={appRightsFieldTypes}
                                        isAppRole={false}
                                        isFormValidated={isFormValidated}
                                        defaultExpanded={
                                            appRightsFieldTypes?.SplitBusinessRequestApprovalPerFieldTypeValue
                                        }
                                        showRequiredFieldMessage={
                                            checkIfRequiredFieldExist(
                                                appRightsFieldTypes,
                                            ) &&
                                            !checkIfAllRequiredFieldFilled()
                                        }
                                    />
                                </Fragment>
                            )}
                        </Box>
                    </Box>
                    {/* Save Button in case of manage access(UI needs tobe updated, Waiting on UX) */}
                    <Box>
                        <Tooltip title={getTooltipTitle()}>
                            <Box
                                padding="18px 30px 32px 30px"
                                className={classes.saveBtnContainer}
                            >
                                <Button
                                    disabled={
                                        checkIfAlreadyAvailableInCart() ||
                                        !checkIfChanged()
                                    }
                                    onClick={handleSave}
                                    className={classes.saveButton}
                                    startIcon={
                                        <Icon
                                            name="AddToCart"
                                            style={{
                                                width: '1.25rem',
                                                height: '1.25rem',
                                            }}
                                            color={
                                                theme?.palette?.common?.white
                                            }
                                            fill={theme?.palette?.common?.white}
                                        />
                                    }
                                >
                                    {addToCartLoading ||
                                    isRemoving ||
                                    addItemsToCartLoading ? (
                                        <Loader
                                            height={'auto'}
                                            color={
                                                theme?.palette?.common?.white
                                            }
                                        />
                                    ) : onSave ? (
                                        t('Save')
                                    ) : (
                                        t('Common_AddToCart')
                                    )}
                                </Button>
                            </Box>
                        </Tooltip>
                    </Box>
                </Fragment>
            ) : isLoading ? (
                <Fragment>
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                </Fragment>
            ) : showInfoMessage ? <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '20rem',
            }}>
                <NoDataFoundMessage message={t('Common_NoFieldTypeFoundToEdit')} />
            </Box> : (
                <GradientSpacer />
            )}
        </Fragment>
    )
}

export default DynamicFieldTypesSelector
