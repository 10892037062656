import { queryCache, useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useAuthState, useAxios } from 'packages/core'
import { useNotification } from 'packages/eid-ui'
import { useAppState } from 'appContext'
import { IRiskFunction } from 'components/Cart/types'
// import { IRiskFunction } from '../components/Cart/types'

export const CART_KEY = 'CART'

const refreshTargetResourceCart = (targetResourceId: string) => {
    queryCache.invalidateQueries(
        (x) =>
            x.queryKey.includes(CART_KEY) &&
            x.queryKey.includes(targetResourceId) &&
            !x.queryKey.includes('PREVIOUS_EVALUATION') &&
            !x.queryKey.includes('RISK_FUNCTIONS'),
    )
}

export const useSetNextTargetResource = (targetResource: any) => {
    const [, dispatch]: any = useAppState()
    dispatch({
        type: 'SET_TARGET_RESOURCE',
        payload: {
            targetAssigneeId: targetResource.targetAssigneeId,
            targetAssigneeFriendlyName:
                targetResource.targetAssigneeFriendlyName,
        },
    })
}

export const removeTargetPersonCartEvaluation = (targetResourceId: string) => {
    const keyFn = (x: any) =>
        x.queryKey.includes(CART_KEY) &&
        x.queryKey.includes(targetResourceId) &&
        x.queryKey.includes('PREVIOUS_EVALUATION')
    const query = queryCache.getQuery(keyFn)
    if (query) {
        queryCache.setQueryData(query.queryKey, undefined)
    }
}

export const refreshAllCarts = () => {
    queryCache.invalidateQueries(
        (c) => c.queryKey.includes(CART_KEY) && c.queryKey.includes('ALL'),
    )
}

export const useTargetPerson = () => {
    const [{ currentUserId }]: any = useAuthState()
    const storageKey = `targetPerson.${currentUserId}:${window.location.origin}`

    const [{ targetPerson }, dispatch]: any = useAppState()

    const setTargetPerson = (targetPerson: any) => {
        sessionStorage.setItem(storageKey, targetPerson.id)
        dispatch({
            type: 'SET_TARGET_PERSON',
            payload: targetPerson,
        })
    }

    const getSavedTargetPersonId = () => sessionStorage.getItem(storageKey)

    const deleteSavedTargetPersonId = () => {
        if (sessionStorage.getItem(storageKey)) {
            sessionStorage.removeItem(storageKey)
        }
    }
    return [
        targetPerson,
        setTargetPerson,
        {
            getSavedTargetPersonId,
            deleteSavedTargetPersonId,
        },
    ]
}

const _useAllCarts = () => {
    const callApi = useAxios()

    return useQuery([CART_KEY, 'ALL'], () =>
        callApi({
            method: 'GET',
            url: '/api/cart/all',
        }),
    )
}

const isActiveCartIsDifferentThenSelected = (targetResource: any, activeCarts: any) => {
    let activeCartIsDiffernt = false;
    try {
        if (activeCarts?.length && targetResource) {
            activeCarts.forEach((element: any) => {
                if (element?.targetAssigneeId !== targetResource?.targetAssigneeId) {
                    activeCartIsDiffernt = true
                }
            });
        }
    } catch (err) {
    }
    return activeCartIsDiffernt
}

export const useAllCarts = () => {
    const { data, isFetching } = _useAllCarts()
    const [{ targetResource }]: any = useAppState()
    if (!data) {
        return { data: undefined, isFetching }
    }

    const activeCarts = data.filter((c: any) => c.cartItems.length > 0)
    // First Check if we more than 1 active cart.
    // Second will check if we active cart and current is not in active list
    const hasMultipleCarts = activeCarts.length > 1 ? true : isActiveCartIsDifferentThenSelected(targetResource, activeCarts)

    return { data: activeCarts, isFetching, hasMultipleCarts }
}

export const useCart = () => {
    const callApi = useAxios()
    const [{ targetResource }]: any = useAppState()
    const data = {
        targetAssigneeId: targetResource?.targetAssigneeId,
        targetAssigneeFriendlyName: targetResource?.targetAssigneeFriendlyName,
        targetAssignee_ResourceTypeFriendlyName:
            targetResource?.targetAssignee_ResourceTypeFriendlyName,
    }

    return useQuery(
        [CART_KEY, targetResource?.targetAssigneeId],
        () =>
            callApi({
                url: 'api/cart',
                method: 'POST',
                data,
            }),
        {
            onSuccess: () => {
                refreshAllCarts()
            },
        },
    )
}

export const useUpdateCartItemComment = () => {
    const callApi = useAxios()
    const [{ targetResource }]: any = useAppState()
    return useMutation(
        (data) =>
            callApi({
                method: 'PUT',
                url: '/api/cart/item/comment',
                data: data,
            }),
        {
            onSuccess: () =>
                refreshTargetResourceCart(targetResource.targetAssigneeId),
        },
    )
}

export const useAddItemToCart = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    const [{ targetResource }]: any = useAppState()
    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/cart/addItem',
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('Common_ItemAddedToCart'))
                removeTargetPersonCartEvaluation(
                    targetResource.targetAssigneeId,
                )
                refreshTargetResourceCart(targetResource.targetAssigneeId)
            },
            onError: () => {
                showWarningMessage(t('Common_FailedToAddItemToCart'))
            },
        },
    )
}

export const useAddItemsToCart = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    const [{ targetResource }]: any = useAppState()
    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/cart/addItems',
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('Common_ItemAddedToCart'))
                removeTargetPersonCartEvaluation(
                    targetResource.targetAssigneeId,
                )
                refreshTargetResourceCart(targetResource.targetAssigneeId)
            },
            onError: () => {
                showWarningMessage(t('Common_FailedToAddItemToCart'))
            },
        },
    )
}

export const useRemoveItemFromCart = () => {
    const callApi = useAxios()
    const [{ targetResource }]: any = useAppState()
    return useMutation(
        (id) =>
            callApi({
                method: 'DELETE',
                url: `/api/cart/deleteItem/${id}/${targetResource.targetAssigneeId}`,
            }),
        {
            onSuccess: () => {
                removeTargetPersonCartEvaluation(
                    targetResource.targetAssigneeId,
                )
                refreshTargetResourceCart(targetResource.targetAssigneeId)
            },
        },
    )
}

export const useRemoveItemsFromCart = () => {
    const callApi = useAxios()
    const [{ targetResource }]: any = useAppState()
    return useMutation(
        (ids) =>
            callApi({
                method: 'DELETE',
                url: `/api/cart/deleteItems/${targetResource.targetAssigneeId}`,
                data: ids
            }),
        {
            onSuccess: () => {
                removeTargetPersonCartEvaluation(
                    targetResource.targetAssigneeId,
                )
                refreshTargetResourceCart(targetResource.targetAssigneeId)
            },
        },
    )
}

export const useRemoveCart = (targetAssigneeId: any) => {
    const callApi = useAxios()
    const [{ targetResource }]: any = useAppState()
    return useMutation(
        () =>
            callApi({
                method: 'DELETE',
                url: `/api/cart/delete/${targetAssigneeId}`,
            }),
        {
            onSuccess: () => {
                removeTargetPersonCartEvaluation(targetAssigneeId)
                refreshTargetResourceCart(targetResource.targetAssigneeId)
            },
        },
    )
}

export const useEvaluateCart = () => {
    const callApi = useAxios()
    const [{ targetResource }]: any = useAppState()
    return useMutation(
        () =>
            callApi({
                method: 'PUT',
                url: `/api/cart/evaluate/${targetResource.targetAssigneeId}`,
            }).then((response) => response.data),
        {
            onSuccess: () => {
                refreshTargetResourceCart(targetResource.targetAssigneeId)
            },
        },
    )
}

export const usePreviousEvaluationResults = () => {
    const { data: cart } = useCart()

    const showRiskData =
        cart &&
        cart.cartItems.length > 0 &&
        !cart.requiresEvaluation &&
        cart.evaluationDateUtc !== null

    const callApi = useAxios()
    const [{ targetResource }]: any = useAppState()
    return useQuery(
        [CART_KEY, 'PREVIOUS_EVALUATION', targetResource?.targetAssigneeId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/cart/evaluationResult/${targetResource.targetAssigneeId}`,
            }).then((response) => response.data),
        {
            enabled: showRiskData,
        },
    )
}

export const useRiskFunctions = (
    localRiskId: string,
    assigneeId: string,
    isLeftSide: boolean,
    inverted: boolean,
) => {
    const callApi = useAxios()

    return useQuery<IRiskFunction[]>(
        [
            CART_KEY,
            localRiskId,
            assigneeId,
            isLeftSide,
            inverted,
            'RISK_FUNCTIONS',
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/cart/riskFunctions/${assigneeId}/${localRiskId}/${isLeftSide ? 'left' : 'right'
                    }?riskDetailsInversed=${inverted}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(localRiskId) && Boolean(assigneeId),
        },
    )
}

export const useSubmitCart = () => {
    const callApi = useAxios()
    const [{ targetResource }]: any = useAppState()
    return useMutation(
        (data) =>
            callApi({
                method: 'PUT',
                url: '/api/cart/submit',
                data: data,
            }),
        {
            onSuccess: () => {
                removeTargetPersonCartEvaluation(
                    targetResource.targetAssigneeId,
                )
                // Need to setup next cart target
                // setTargetPerson(currentPerson)
                refreshTargetResourceCart(targetResource.targetAssigneeId)
            },
        },
    )
}

export const usePredefinedJustifications = () => {
    const callApi = useAxios()
    return useQuery(
        [CART_KEY, 'PREDEFINED_JUSTIFICATIONS'],
        () =>
            callApi({
                method: 'GET',
                url: 'api/cart/predefinedJustifications',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useCreateCart = () => {
    const callApi = useAxios()
    return useMutation((targetResource: [any]) =>
        callApi({
            method: 'POST',
            url: `api/cart`,
            data: {
                ...targetResource,
            },
        }),
    )
}
